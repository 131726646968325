import React, { useEffect } from "react";
import ClockLogo from './../../../assets/images/logo1.png';
import './Analog-clock.css';

export const AnalogClock = () => {

    useEffect(() => {
        document.title = 'Create an Analog Clock using JavaScript, HTML and CSS - Nexara';
        window.scrollTo(0, 0)
    }, []);
    // setInterval(() => {
    //     let date = new Date(); //get the date
    //     let hr = date.getHours();
    //     let min = date.getMinutes();
    //     let sec = date.getSeconds();
    //     let hr_rotation = 30 * hr + min / 2; //converting current time
    //     let min_rotation = 6 * min;
    //     let sec_rotation = 6 * sec;

    //     let hour = document.querySelector('#hour');
    //     let minute = document.querySelector('#minute');
    //     let second = document.querySelector('#second');
    //     if(hour && minute && second){
    //      hour.style.transform = `rotate(${hr_rotation}deg)`;
    //     minute.style.transform = `rotate(${min_rotation}deg)`;
    //     second.style.transform = `rotate(${sec_rotation}deg)`;
    //     }
    // }, 1000);

    function rotateClockHands() {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        const hourHand = document.getElementById('hour-hand');
        const minuteHand = document.getElementById('minute-hand');
        const secondHand = document.getElementById('second-hand');

        const hourAngle = (hours % 12) * 30 + minutes / 2; // 30 degrees per hour, 0.5 degrees per minute
        const minuteAngle = minutes * 6; // 6 degrees per minute
        const secondAngle = seconds * 6; // 6 degrees per second
        if (hourHand && minuteHand && secondHand) {
            hourHand.style.transform = `rotate(${hourAngle}deg)`;
            minuteHand.style.transform = `rotate(${minuteAngle}deg)`;
            secondHand.style.transform = `rotate(${secondAngle}deg)`;
        }
    }

    // Call rotateClockHands every second
    setInterval(rotateClockHands, 1000);

    // Initialize clock on page load
    rotateClockHands();


    function copyTextToClipboard(event, codeToCopyId) {
        let clickedBtn = event.target;
        const textToCopy = document.getElementById(codeToCopyId).innerText;

        // Create a temporary textarea element
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = textToCopy;
        document.body.appendChild(tempTextarea);

        // Select the text in the textarea
        tempTextarea.select();
        document.execCommand('copy');

        // Clean up by removing the temporary textarea
        document.body.removeChild(tempTextarea);

        // Optionally, provide user feedback (e.g., change button text)
        // document.getElementById('copyButton').innerText = 'Copied!';
        clickedBtn.innerText = 'Copied!'
        setTimeout(() => { clickedBtn.innerText = 'Copy code!' }, 4000)
    }
    // setTimeout(()=>{clickedBtn.innerText = 'Copy code!'}, 4000)

    // document.getElementById('copyButton').addEventListener('click', copyTextToClipboard);

    return (
        <div className="analog-clock-blog-container light-bg">
            {/* <div id="clock-container">
            <div id="hour"></div>
            <div id="minute"></div>
            <div id="second"></div>
        </div> */}
            <div className="container">
                <div className="subpage-header">
                    <div className="row">
                        <div className="col-md-9">
                            <h1 className="mt-5">Creating an Analog Clock Using JavaScript: A Step-by-Step Guide</h1>
                        </div>
                        <div className="col-md-3 justify-end">
                            <div className="clock blog-demo mt-3 mb-5">
                                <div className="hour-hand" id="hour-hand"></div>
                                <div className="minute-hand" id="minute-hand"></div>
                                <div className="second-hand" id="second-hand"></div>
                                <div className="center-circle"></div>
                                <div className="clock-logo"><img src={ClockLogo} alt="logo image"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dark-bg">
            <div className="container p-tb-40">
                <div className="row">
                    <div className="col-md-9">


                        {/* <div className="clock blog-demo mt-3 mb-5">
                            <div className="hour-hand" id="hour-hand"></div>
                            <div className="minute-hand" id="minute-hand"></div>
                            <div className="second-hand" id="second-hand"></div>
                            <div className="center-circle"></div>
                        </div> */}


                        <p className="mb-5">Welcome, coding enthusiasts! Today, we're diving into the world of JavaScript to create something classic yet elegant: an analog clock. With just a few lines of JavaScript and some basic HTML and CSS, you'll be able to craft a timeless piece that's not only functional but also a great way to improve your understanding of JavaScript.</p>
                        <p>Let's get started!</p>
                        <h2>Step 1: Set Up Your HTML Structure</h2>
                        <p>First things first, let's create the skeleton of our clock. In your HTML file, add the following markup:</p>
                        <div className="code-snippet mb-5 mt-5">
                            <button id="copyButton" className="copybtn" onClick={(e) => copyTextToClipboard(e, 'htmlcode')}>Copy Code</button>
                            <div id="htmlcode">
                                <pre>
                                    <code >
                                        <div className="code-snnippet-container">
                                            <div>&#x3C;!DOCTYPE html&#x3E;</div>
                                            <div>&#x3C;html lang=&#x22;en&#x22;&#x3E;</div>
                                            <div>&#x3C;head&#x3E;</div>
                                            <div>&#x3C;meta charset=&#x22;UTF-8&#x22;&#x3E;</div>
                                            <div>&#x3C;meta name=&#x22;viewport&#x22; content=&#x22;width=device-width, initial-scale=1.0&#x22;&#x3E;</div>
                                            <div>&#x3C;title&#x3E;Analog Clock&#x3C;/title&#x3E;</div>
                                            <div>&#x3C;link rel=&#x22;stylesheet&#x22; href=&#x22;styles.css&#x22;&#x3E;</div>
                                            <div>&#x3C;/head&#x3E;</div>
                                            <div>&#x3C;body&#x3E;</div>
                                            <div>&#x3C;div class=&#x22;clock&#x22;&#x3E;</div>
                                            <div>&#x3C;div class=&#x22;hour-hand&#x22; id=&#x22;hour-hand&#x22;&#x3E;&#x3C;/div&#x3E;</div>
                                            <div>&#x3C;div class=&#x22;minute-hand&#x22; id=&#x22;minute-hand&#x22;&#x3E;&#x3C;/div&#x3E;</div>
                                            <div>&#x3C;div class=&#x22;second-hand&#x22; id=&#x22;second-hand&#x22;&#x3E;&#x3C;/div&#x3E;</div>
                                            <div>&#x3C;div class=&#x22;center-circle&#x22;&#x3E;&#x3C;/div&#x3E;</div>
                                            <div>&#x3C;/div&#x3E;</div>
                                            <div>&#x3C;script src=&#x22;script.js&#x22;&#x3E;&#x3C;/script&#x3E;</div>
                                            <div>&#x3C;/body&#x3E;</div>
                                            <div>&#x3C;/html&#x3E;</div>
                                        </div>
                                    </code>
                                </pre>
                            </div>
                        </div>
                        <h2>Step 2: Style Your Clock</h2>
                        <p>Let's make our clock look good! Create a styles.css file and add the following CSS:</p>
                        <div className="code-snippet mb-5 mt-5">
                            <button id="copyCSSBtn" className="copybtn" onClick={(e) => copyTextToClipboard(e, 'csscode')}>Copy Code</button>
                            <div id="csscode">
                                <pre>

                                    <code>

                                        <div className="code-snnippet-container">
                                            <div>.clock &#123;</div>
                                            <div>position: relative;</div>
                                            <div>margin: auto;</div>
                                            <div>height: 200px;</div>
                                            <div>width: 200px;</div>
                                            <div>border: 1px solid #ffffff;</div>
                                            <div>border-radius: 50%;</div>
                                            <div>background-color: #FFF;</div>
                                            <div>background-image: url('./../../../assets/images/clock.png');</div>
                                            <div>background-size: cover;</div>
                                            <div>&#125;</div>

                                            <div>.hour-hand,</div>
                                            <div>.minute-hand,</div>
                                            <div>.second-hand &#123;</div>
                                            <div>position: absolute;</div>
                                            <div>background: black;</div>
                                            <div>border-radius: 10px;</div>
                                            <div>transform-origin: bottom;</div>
                                            <div>&#125;</div>

                                            <div>.hour-hand &#123;</div>
                                            <div>width: 1.8%;</div>
                                            <div>height: 25%;</div>
                                            <div>top: 25%;</div>
                                            <div>left: 48.85%;</div>
                                            <div>opacity: 0.8;</div>
                                            <div>&#125;</div>

                                            <div>.minute-hand &#123;</div>
                                            <div>width: 1.6%;</div>
                                            <div>height: 30%;</div>
                                            <div>top: 19%;</div>
                                            <div>left: 48.9%;</div>
                                            <div>opacity: 0.8;</div>
                                            <div>&#125;</div>

                                            <div>.second-hand &#123;</div>
                                            <div>width: 1%;</div>
                                            <div>height: 40%;</div>
                                            <div>top: 9%;</div>
                                            <div>left: 49.25%;</div>
                                            <div>opacity: 0.8;</div>
                                            <div>background: red;</div>
                                            <div>&#125;</div>

                                            <div>.center-circle &#123;</div>
                                            <div>width: 12px;</div>
                                            <div>height: 12px;</div>
                                            <div>background-color: #333;</div>
                                            <div>border-radius: 50%;</div>
                                            <div>position: absolute;</div>
                                            <div>top: 0;</div>
                                            <div>left: 0;</div>
                                            <div>bottom: 0;</div>
                                            <div>right: 0;</div>
                                            <div>margin: auto;</div>
                                            <div>&#125;</div>
                                        </div>
                                    </code>
                                </pre>
                            </div>
                        </div>
                        <h2>Step 3: Add JavaScript Functionality</h2>
                        <p>Now comes the fun part! Create a script.js file and let's add the JavaScript to make our clock tick:</p>
                        <div className="code-snippet mb-5 mt-5">
                            <button id="copyJSBtn" className="copybtn" onClick={(e) => copyTextToClipboard(e, 'jscode')}>Copy Code</button>
                            <div id="jscode">
                                <pre>
                                    <code>
                                        <div className="code-snnippet-container">
                                            <div>function rotateClockHands() &#123;</div>
                                            <div>const now = new Date();</div>
                                            <div>const hours = now.getHours();</div>
                                            <div>const minutes = now.getMinutes();</div>
                                            <div>const seconds = now.getSeconds();</div>

                                            <div></div>const hourHand = document.getElementById(&#x27;hour-hand&#x27;);
                                            <div></div>const minuteHand = document.getElementById(&#x27;minute-hand&#x27;);
                                            <div></div>const secondHand = document.getElementById(&#x27;second-hand&#x27;);

                                            <div></div>const hourAngle = (hours % 12) * 30 + minutes / 2; // 30 degrees per hour, 0.5 degrees per minute
                                            <div></div>const minuteAngle = minutes * 6; // 6 degrees per minute
                                            <div></div>const secondAngle = seconds * 6; // 6 degrees per second

                                            <div></div>hourHand.style.transform = &#x60;rotate($&#123;hourAngle&#125;deg)&#x60;;
                                            <div></div>minuteHand.style.transform = &#x60;rotate($&#123;minuteAngle&#125;deg)&#x60;;
                                            <div></div>secondHand.style.transform = &#x60;rotate($&#123;secondAngle&#125;deg)&#x60;;
                                            <div></div>&#125;
                                            <br />
                                            <br />
                                            <div></div>// Call rotateClockHands every second
                                            <div></div>setInterval(rotateClockHands, 1000);
                                            <br />
                                            <br />
                                            <div></div>// Initialize clock on page load
                                            <div></div>rotateClockHands();
                                        </div>
                                    </code>

                                </pre>
                            </div>
                        </div>
                        <h2>Step 4: Test Your Clock</h2>
                        <p>Open your HTML file in a web browser, and voilà! You should see a beautiful analog clock ticking away the seconds.</p>
                        <h2>Conclusion</h2>
                        <p>Congratulations! You've successfully built an analog clock using JavaScript. This project not only enhances your JavaScript skills but also gives you a fun and practical way to understand how to manipulate the DOM and work with time in JavaScript.</p>

                        <p>Feel free to experiment further by adding features like different clock designs, time zones, or even animations to make your clock even more unique. Happy coding!</p>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
            </div>

        </div>

    );
};


