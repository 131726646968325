import React from "react";
import './Specialized.css';
import { Link } from 'react-router-dom';
import web from './../../assets/images/web-app.webp';
import Performance from './../../assets/images/performance-imp.WebP';
import Specialization from './../../assets/images/ourspecialization.png';
import { ReactComponent as Arrow} from './../../assets/svgs/rightArraow.svg';
import QAtest from './../../assets/images/about/qa-testing-banner.webp'



export const Specialized = () => {
    function AddBg(event, backgroundImg) {

            // document.querySelector(".specialization-container").style.backgroundColor = backgroundImg;
             document.querySelector(".default").style.display = 'none';
           
    }
    function removeBg(event) {
            // document.querySelector(".specialization-container").style.backgroundColor = '#02012d';
         document.querySelector(".default").style.display = 'block';
    }
    return (
        <>
            <div className="specialization-container p-tb-40 ">
                <div className="container-fluid">
                    <h2 className="text-center">Services at Nexara</h2>
                    <div className="specialization-list">
                    <div className="specialization" >
                        <Link to='/webofferings' className="specilization-header" onMouseOver={(e) => AddBg(e, '#02012d')} onMouseOut={(e) => removeBg(e)}><h4>Web Development <Arrow width="20px" height="20px"/></h4></Link>
                        <div className="specilization-content ">
                             <div className="img-container"><img src={web} alt="Web services" fetchpriority="low"/></div> 

                            <div className="display-container web">
                               
                               <div className="banner-solutions">
                                   <div className="bs">
                                   From stunning websites to robust e-commerce platforms, we kickstart your digital journey and scale it to new heights. Our responsive designs and seamless functionalities guarantee an exceptional user experience for your customers.
                                   </div>
                                   

                               </div>
                               
                              
                           </div>
                        </div>
                        <div className="specilization-content default">
                           <div className="img-container"><img src={Specialization} alt="services" fetchpriority="low"/></div> 
                            <div className="display-container">
                               
                               <div className="banner-solutions">
                                   <div className="bs">
                                   Nexara is your hybrid agency specializing in Software Testing (QA), Web Development, and Digital Marketing.
                                   </div>
                                   

                               </div>
                               
                              
                           </div>
                        </div>
                       
                        
                      
                    </div>
                    <div className="specialization" >
                    <Link to='/qaofferings' className="specilization-header" onMouseOver={(e) => AddBg(e, '#02012d')} onMouseOut={(e) => removeBg(e)}><h4>Quality Assurance<Arrow width="20px" height="20px"/></h4></Link>
                    <div className="specilization-content ">
                             <div className="img-container"><img src={QAtest} alt="testing services" fetchpriority="low"/></div> 
                            <div className="display-container">
                               
                               <div className="banner-solutions">
                                   <div className="bs">
                                   We ensure top-notch quality for your software products. By taking QA completely off your plates, we enable your teams to focus on innovation and speed to market.
                                   </div>
                                   

                               </div>
                               
                              
                           </div>
                           
                        </div>
                    </div>
                    <div className="specialization" >
                    <Link to='/marketingofferings' className="specilization-header" onMouseOver={(e) => AddBg(e, '#02012d')} onMouseOut={(e) => removeBg(e)}><h4>Marketing & Lead Generation<Arrow width="20px" height="20px"/></h4></Link>
                    <div className="specilization-content ">
                           <div className="img-container performance"><img src={Performance} alt="Marketing services" fetchpriority="low"/></div> 
                            <div className="display-container">
                               
                               <div className="banner-solutions">
                                   <div className="bs">
                                   Get ready to stand out in the digital landscape. We craft strategic digital marketing campaigns that drive traffic, generate leads, and boost conversions, ensuring your brand's success online.
                                   </div>
                                   

                               </div>
                               
                              
                           </div>
                        </div>
                    </div>
              
                    </div>
                    
                </div>
            </div>
        </>
    );
};
