import React, {useEffect} from "react";
import './Thankyou.css';
import { ReactComponent as Arrow } from './../../assets/svgs/rightArraow.svg';
import { HashLink } from 'react-router-hash-link';

export const Thankyou = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
         <div className=" subpage-header thankyou-page">
                <div className="container-fluid">
                    <h1>Thank You</h1>
                    <div className="thankyou-icon"><i className="bi bi-check-circle"></i></div>
                     <p className="mb-3 subpage-sub-header">Thank you for reaching out to us! We look forward to speaking with you soon!</p> 
                     <p className="grey-text">Our team is currently reviewing your message, and we will get back to you as soon as possible with a response.</p>
                    
                     <HashLink smooth to='/#services' className="contact-us-btn mt-5"> Explore Our Services </HashLink>
                </div>
            </div>
        </>
    )
}