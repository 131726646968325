import {useEffect} from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Suspense, lazy } from "react";


import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { Whatwedo } from './components/WhatWeDo/WhatWeDo';
import { LetsTalk } from './components/LetsTalk/LetsTalk';
import { Home } from './components/Home/Home';
import {Qa} from './components/Qa/Qa';
import { Web } from './components/WebOfferings/Web';
import { Marketing } from './components/Marketing/Marketing';
import { ReactComponent as Arrow} from './assets/svgs/rightArraow.svg';
import { Blogs } from './components/Blogs/Blogs';
import { AnalogClock } from './components/Blogs-collection/Analog-clock/Analog-clock';
import { SeoBlog } from './components/Blogs-collection/Seo-Blog/Seo';
import { Thankyou } from './components/Thankyou/Thankyou';
import { PerformanceBlog } from './components/Blogs-collection/Performance-Blog/Performance';

import { BrowserRouter, Routes, Route } from "react-router-dom";



function App() {
  // useEffect(() => {
  //   // 👇️ Scroll to top on page load
  //   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  // }, []);
 
  useEffect(() => {
    window.scrollTo(0, 0);
    if(window.location.hash){
      let hashVal = window.location.hash.substring(1);
      const myElement = document.getElementById(hashVal);
      myElement?.scrollIntoView()
    }
  }, [])
  

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};
let currentScrollPos = 0;
function scrollFunction() {
  let goUp = document.getElementById("goUp");
  currentScrollPos = document.body.scrollTop || document.documentElement.scrollTop;
  if (document.body.scrollTop < 100 || document.documentElement.scrollTop < 100) {
    //console.log('document.body.scrollTop', document.body.scrollTop, document.documentElement.scrollTop)
    if(goUp)
    goUp.style.display = "none";
    document.querySelector('.fixedNav').style.display = 'none';
    // if( document.querySelector('.blog-demo')){
    // document.querySelector('.blog-demo').classList.remove('fix-blog-demo');
    // }
    
  } 
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    if(goUp)
    goUp.style.display = "block";
    document.querySelector('.fixedNav').style.display = 'block';
    // if( document.querySelector('.blog-demo')){
    // document.querySelector('.blog-demo').classList.add('fix-blog-demo');
    // }
  }
  if(currentScrollPos > document.body.scrollTop || document.documentElement.scrollTop){
//show nav
// console.log('currentScrollPos', currentScrollPos)
// console.log('document.documentElement.scrollTop', document.documentElement.scrollTop)
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}


function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;
    // console.log('elementTop', elementTop)
    // console.log('windowHeight', windowHeight)

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}


window.addEventListener("scroll", reveal);

  return (
    <div className="">
      
        {/* <Header />
       
        <Banner />
        <Whatwedo />
        <WhyChooseUs />
        <OurSolutions />
        <LetsTalk />
       
        <Footer /> */}

<BrowserRouter>
<div className=''>
<Header /> 
</div>
      <Routes>
      
        <Route path="/" element={<Home />} />
        <Route index element={<Home />} />
          {/* <Route index element={<Banner />} /> */}
          <Route path="service" element={<Whatwedo />} />
          <Route path="contact" element={<LetsTalk />} />
          <Route path="qaofferings" element={<Qa />} />
          <Route path="webofferings" element={<Web />} />
          <Route path="marketingofferings" element={<Marketing />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blogs/analogclock" element={<AnalogClock />} />
          <Route path="blogs/seo" element={<SeoBlog />} />
          <Route path="thankyou" element={<Thankyou />} />
           <Route path="blogs/performance-impovement" element={<PerformanceBlog />} /> 
          
          
           <Route path="*" element={<Home />} />
          
       
      </Routes>
      <div className="pos-relative mt-5">
       <div className="curved-separater footer">
      <Footer />
      </div>
      </div>
      {/* <Chatbot /> */}
      <button className="timer-wrapper" id="goUp" onClick={topFunction}><Arrow width="15px" height="15px" aria-label="go up"/></button>
        <div className='fixed-contact-info'>
          <div className=''>
            <a href='tel:+919900121537'>
              <div className='d-flex flex-row align-center'>
              <div><i className="bi bi-telephone"></i></div>
              <div className='contact-info-det'>
                <p>Call us now:</p>
                <p><b>+91 990 012 1537</b></p>
              </div>
              </div>
              </a>
              </div>
         
        </div>
        <div className='fixed-contact-info top-calc'>
        <div>
          <a href="https://wa.me/+919900121537" target="_blank">
          
          <div className='d-flex flex-row align-center'>
              <div><i className="bi bi-whatsapp"></i></div>
              <div className='contact-info-det'>
                <p>Text us now:</p>
                <p><b>+91 990 012 1537</b></p>
              </div>
              </div>
          </a>
          </div>
        </div>

        <div className='fixed-contact-info top-calc-email'>
        <div>
          <a href="mailto:info@nexara.in">
          <div className='d-flex flex-row align-center'>
              <div><i className="bi bi-envelope"></i></div>
              <div className='contact-info-det'>
                <p>Email us now:</p>
                <p><b>info@nexara.in</b></p>
              </div>
              </div>
          </a>
          </div>
        </div>
     
    </BrowserRouter>
      
    </div>
  );
}

export default App;
