import React, { useEffect } from "react";
import './Blogs.css'
import ClockLogo from './../../assets/images/logo1.png';
import { ReactComponent as Boost } from './../../assets/svgs/Blogs/boost.svg';
import { Link } from "react-router-dom";


export const Blogs = () => {
    useEffect(() => {
        // document.title = 'Front end Blogs | Build Websites, Test, Grow: The Nexara Way ';
        window.scrollTo(0, 0)
    }, []);

    function rotateClockHands() {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        const hourHand = document.getElementById('hour-hand');
        const minuteHand = document.getElementById('minute-hand');
        const secondHand = document.getElementById('second-hand');

        const hourAngle = (hours % 12) * 30 + minutes / 2; // 30 degrees per hour, 0.5 degrees per minute
        const minuteAngle = minutes * 6; // 6 degrees per minute
        const secondAngle = seconds * 6; // 6 degrees per second
        if (hourHand && minuteHand && secondHand) {
            hourHand.style.transform = `rotate(${hourAngle}deg)`;
            minuteHand.style.transform = `rotate(${minuteAngle}deg)`;
            secondHand.style.transform = `rotate(${secondAngle}deg)`;
        }
    }

    // Call rotateClockHands every second
    setInterval(rotateClockHands, 1000);

    // Initialize clock on page load
    rotateClockHands();

    return (

        <div className="blog-container">
            <div className="">
                <div className="container">
                    <div className="subpage-header  ">
                        <h1 className="">Blogs</h1>
                    </div>
                </div>
            </div>
            <div className="dark-bg">
                <div className="container p-tb-40">
            <div className="row">
            <div className="col-md-3 p-tb-20">
                <Link to="/blogs/analogclock" >
                    <div className="img-container-100p">
                    <div className="clock mt-3 mb-5">
                                <div className="hour-hand" id="hour-hand"></div>
                                <div className="minute-hand" id="minute-hand"></div>
                                <div className="second-hand" id="second-hand"></div>
                                <div className="center-circle"></div>
                                <div className="clock-logo"><img src={ClockLogo} alt="logo image"/></div>
                            </div>
                    </div>
                    <h4 className="mt-4">How to build a Customizable Analog clock</h4>
                    </Link>
                </div>
                <div className="col-md-3 p-tb-20">
                    <Link to="/blogs/performance-impovement" >
                    <div className="img-container-100p">
                        <Boost />
                    </div>
                    <h4 className="mt-4">Boost Your Website: 5 Proven Steps to Improve Performance</h4>
                    </Link>
                </div>
                {/* <div className="col-md-3 p-tb-20">
                    <div className="img-container-100p">
                        <img src={SEO} alt="seo implementation" />
                    </div>
                    <h3>Accessible forms</h3>
                </div>
                <div className="col-md-3 p-tb-20">
                    <div className="img-container-100p">
                        <img src={SEO} alt="seo implementation" />
                    </div>
                    <h3>Create your own chatbot</h3>
                </div>
                <div className="col-md-3 offset-md-1 p-tb-20">
                    <div className="img-container-100p">
                        <img src={SEO} alt="seo implementation" />
                    </div>
                    <h3>Dynamic sliders</h3>
                </div> */}
              
                {/* <div className="col-md-3 p-tb-20">
                    <div className="img-container-100p">
                        <img src={SEO} alt="seo implementation" />
                    </div>
                    <h3>Color palette selector for a website</h3>
                </div> */}
            </div>

            </div>
            </div>
        </div>
    );
};
