import React, { useRef} from "react";
import './LetsTalk.css';
import GinT from './../../assets/images/get-in-touch.webp';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';


export const LetsTalk = () => {
    const form = useRef();

    const navigate = useNavigate();
    

const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_d8iapt6', 'template_c368e0q', form.current, {
        publicKey: 'YukjYo-nH4LO1L5IO',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          navigate('/thankyou', { replace: true });

        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
    return (
        <div className="">
            <div className="banner-container container-fluid">
            <h2 className="contact-us-text text-center">
                               Have a Question? Let's Connect
                            </h2>
                <div className="row align-center">
                   
                    <div className="col-lg-6 mb-5 p-t-60">
                        <div className="contact-us-conatiner">

                           
                            <div className="contact-us-form">
                            
                                {/* <form action="mailto:shridevikupluchar146@gmail.com" method="POST" encType="multipart/form-data" name="EmailForm"> */}
                                {/* <form action="https://getform.io/f/qaloeqkb" method="POST" ref={form} onSubmit={sendEmail}> */}
                                <form ref={form} onSubmit={sendEmail}>
                                    <div >
                                        <div className="">
                                            <label htmlFor="fname" className="required">
                                                Full Name
                                            </label>
                                        </div>
                                        <input
                                            id="fname"
                                            type="text"
                                            className=""
                                            name="fname"
                                            placeholder="Enter your full name"
                                            required
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <div className="">
                                            <label htmlFor="email" className="required">
                                                Email
                                            </label>
                                        </div>
                                        <input
                                            id="email"
                                            type="email"
                                            className=" "
                                            name="email"
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <div className="">
                                            <label htmlFor="mobile" className="">
                                                Mobile 
                                            </label>
                                        </div>
                                        <input
                                            id="mobile"
                                            type="tel"
                                            className=" "
                                            name="mobile"
                                            placeholder="Enter your mobile number"
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <div className="">
                                            <label htmlFor="message" className="required">
                                                Message 
                                            </label>
                                        </div>
                                        <textarea
                                            id="message"
                                            className=" "
                                            placeholder="Enter your message here"
                                            name="message"
                                            maxLength="500"
                                            required
                                        ></textarea>
                                    </div>
                                    <input type="submit" value="Get In touch" className="contact-us-btn"/> 
                                </form>
                              
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6 p-t-60">
                        <div className="">

                        <div className="img-container">
                                <img src={GinT} alt="contact us, lets talk" fetchpriority="low" />
                            </div>
                          
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};
