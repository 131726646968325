import React, {useRef} from "react";
import './Approach.css';
import { ReactComponent as Discovery } from './../../assets/svgs/Approach/discovery.svg';
import { ReactComponent as Design } from './../../assets/svgs/Approach/design.svg';
import { ReactComponent as Dev } from './../../assets/svgs/Approach/dev.svg';
import { ReactComponent as Test } from './../../assets/svgs/Approach/test.svg';
import { ReactComponent as Optimisation } from './../../assets/svgs/Approach/optimization.svg';
import { ReactComponent as Launch } from './../../assets/svgs/Approach/launch.svg';
import { ReactComponent as Support } from './../../assets/svgs/Approach/support.svg';
import { ReactComponent as Reasearch } from './../../assets/svgs/Approach/reasearch.svg';
import { ReactComponent as KeyWord } from './../../assets/svgs/Approach/keyword.svg';
import { ReactComponent as Content } from './../../assets/svgs/Approach/content.svg';
import { ReactComponent as PPC } from './../../assets/svgs/Approach/ppc.svg';
import { ReactComponent as Social } from './../../assets/svgs/Approach/social.svg';
import { ReactComponent as EmailM } from './../../assets/svgs/Approach/emailM.svg';
import { ReactComponent as Analytics } from './../../assets/svgs/Approach/analytics.svg';


export const Approach = (prop, title) => {
    let readMore = useRef();
   const closeNav = () => {
    if(readMore.current){
    readMore.current.style.display = 'none';
    }
   }
   const readMoreBtn = () => {
    if(readMore.current){
    readMore.current.style.display = 'block';
    }
   }
    return (
        <div id="approach">
            <h2 className="text-center mb-5">{prop.title}</h2>
            {prop.title && prop.title === "QA Approach" && <div className="row">

                {prop.approach.map((ap) => (
                    <div className="col-md-4 qa-apr-box" key={ap.step}>
                        <div className={ap.more && ap.more === 'yes' ? "approach-block has-more" : "approach-block"} >

                            <div className="step-count">{ap.step}</div>
                            <div className="approach-container">
                                <h4 className="mt-4">{ap.phase}</h4>
                                <p className="mt-3">{ap.text}</p>
                                {ap.more && ap.more === 'yes' &&
                                    <div>
                                        <div className="more-btn" onClick={readMoreBtn}>Read more</div>
                                        <div className="more-content" ref={readMore}>
                                        <button className="closeMore" onClick={closeNav} aria-label="close more"><i className="bi bi-x-lg"></i></button>
                                            <div className="row">
                                                {ap.test && ap.test.map((more) => (
                                                    <div className="col-md-6" key={more.title}>
                                                        <h4 className="mt-2">{more.title}</h4>
                                                        <p className="mt-2">{more.desc}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                ))}

            </div>
            }
            {prop.title && prop.title === "Web Development Approach" && <div className="row">
                {prop.approach.map((ap) => (
                    <div key={ap.step} className={ap.step === '1' ? 'col-md-9 offset-md-2 lavender' : ap.step === '2' ? 'col-md-9 offset-md-1' : ap.step === '3' ? 'col-md-9 offset-md-2 lightblue' : ap.step === '4' ? 'col-md-9 offset-md-3 lightpink' : ap.step === '5' ? 'col-md-9 offset-md-2' : ap.step === '6' ? 'col-md-9 offset-md-1 lavender' : ap.step === '7' ? 'col-md-9 offset-md-2 lightblue' : 'col-md-9 offset-md-3 lavender'}>
                        <div className="approach ">
                            <div className="pos-relative">
                                <div>
                                    <i className="arrow left"></i>
                                    {/* <div className="step-track">{ap.step}</div> */}
                                    <div className="approach-text-container">
                                        {ap.icon && ap.icon === 'discovery' && <Discovery />}
                                        {ap.icon && ap.icon === 'plan' && <Design />}
                                        {ap.icon && ap.icon === 'dev' && <Dev />}
                                        {ap.icon && ap.icon === 'test' && <Test />}
                                        {ap.icon && ap.icon === 'optimization' && <Optimisation />}
                                        {ap.icon && ap.icon === 'launch' && <Launch />}
                                        {ap.icon && ap.icon === 'support' && <Support />}
                                        <h4 className="phase">{ap.phase}</h4>
                                        <p className="phase-desc">{ap.text}</p>
                                    </div>
                                    <div className="triangle-shadow"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            }


            {prop.title && prop.title === "Digital Marketing Approach" && <div className="row marketing-approach">
                {prop.approach.map((ap, i) => (
                    <div key={ap.step} className="col-lg-2 col-md-3">
                 
                        <div className="jigsaw">
                        {i/6 >= 1 && <span className="t"></span>}
                            {i % 6 !== 5 && <span className="r"></span>}
                            {i/6 < 1 && <span className="b"></span> }
                            {i % 6 !== 0 && <span className="l"></span>}
                            <div className="step-num">{ap.step}</div>
                            <div className="text">
                            <h4 className="phase">{ap.phase}</h4>
                                    <p className="phase-desc">{ap.text}</p>
                                
                                
                              


                            </div>
                            <div className="marketing-svg">
                                    {ap.icon && ap.icon === 'discovery' && <Discovery />}
                                    {ap.icon && ap.icon === 'research' && <Reasearch />}
                                    {ap.icon && ap.icon === 'analysis' && <KeyWord />}
                                    {ap.icon && ap.icon === 'content' && <Content />}
                                    {ap.icon && ap.icon === 'optimization' && <Optimisation />}
                                    {ap.icon && ap.icon === 'ppc' && <PPC />}
                                    {ap.icon && ap.icon === 'analytics' && <Analytics />}
                                    {ap.icon && ap.icon === 'media' && <Social />}
                                    {ap.icon && ap.icon === 'email' && <EmailM />}
                                  
                                </div>
                        </div>
                    </div>
                ))}
            </div>
            }

        

        </div>
    )
}