import React, {useEffect} from "react";
import './Home.css';
import { Banner } from "../Banner/Banner";
import { WhyChooseUs } from "../WhyChooseUs/WhyChooseUs";
import { LetsTalk } from "../LetsTalk/LetsTalk";
import { Specialized } from "../Specialized/Specialized";
import { About } from "../About/About";
import { Faq } from "../Faq/Faq";


 

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Build Websites, Test, Grow, SEO optimization, lead generation: Nexara Solutions';
  }, []);
  
    return (
        <>
        {/* <Header /> */}
       <div className="">
        <Banner />
        </div>
        {/* <Whatwedo /> */}
       
        <WhyChooseUs />
        
        <div className="pos-relative" id="services">
       <div className="curved-separater blue-bg reverted">
        <Specialized />
        </div>
        </div>
        <div id="aboutus">
        <About />
        </div>
        
        {/* <OurSolutions />
        <LetsTalk /> */}
        <div className="light-bg p-t-60" id="contactus">
        <LetsTalk />
        </div>
        <div className="line-seperator">
        {/* <Faq /> */}
        </div>
        {/* <svg className="editorial"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 24 150 28 "
     preserveAspectRatio="none">
 <defs>
 <path id="gentle-wave"
 d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z" />
  </defs>
  <g className="parallax1">
   <use xlink:href="#gentle-wave" x="50" y="3" fill="#f461c1"/>
  </g>
    <g className="parallax2">
   <use xlink:href="#gentle-wave" x="50" y="0" fill="#4579e2"/>
    </g>
      <g className="parallax3">
   <use xlink:href="#gentle-wave" x="50" y="9" fill="#3461c1"/>
   </g>
    <g className="parallax4">
   <use xlink:href="#gentle-wave" x="50" y="6" fill="#fff"/>  
  </g>
</svg> */}

        {/* <Footer />  */}
      </>
    );
};
