import React, {useEffect} from "react";
import './Qa.css';
import { ReactComponent as Target } from './../../assets/svgs/QA/solution.svg';
import { ReactComponent as Report } from './../../assets/svgs/report.svg';
import { ReactComponent as Bottleneck } from './../../assets/svgs/bottleneck.svg';
import { ReactComponent as Optimization } from './../../assets/svgs/optimization.svg';
import { ReactComponent as Money } from './../../assets/svgs/money.svg';
import { ReactComponent as Slow } from './../../assets/svgs/Marketing/bug.svg';
import { Approach } from "../Approach/Approach";
import { ReactComponent as Performance } from './../../assets/svgs/QA/offerings/performance.svg';
import { ReactComponent as Automation } from './../../assets/svgs/QA/offerings/automation.svg';
import { ReactComponent as ManagedQA } from './../../assets/svgs/QA/offerings/managedQA.svg';
import { ReactComponent as MobileTest } from './../../assets/svgs/QA/offerings/mobiletest.svg';
import { ReactComponent as Acc } from './../../assets/svgs/QA/offerings/accessibilitytest.svg';
import { ReactComponent as Explore } from './../../assets/svgs/QA/offerings/explore.svg';
import { ReactComponent as Security } from './../../assets/svgs/QA/offerings/security.svg';
import { ReactComponent as QaImp } from './../../assets/svgs/QA/offerings/qa-imp.svg';
import { ReactComponent as Skill } from './../../assets/svgs/QA/offerings/skill.svg';
import { ReactComponent as Support } from './../../assets/svgs/Approach/support.svg';
import { ReactComponent as Team } from './../../assets/svgs/QA/team.svg';
import { ReactComponent as ProcessUpdate } from './../../assets/svgs/QA/process-update.svg';
import { ReactComponent as TestReport } from './../../assets/svgs/QA/test-report.svg';
import { ReactComponent as Tools } from './../../assets/svgs/QA/tools.svg';
import { ReactComponent as Coverage } from './../../assets/svgs/QA/coverage.svg';
import { ReactComponent as Audit } from './../../assets/svgs/QA/audit.svg';
import { ReactComponent as Usability } from './../../assets/svgs/QA/usability.svg';
import { ReactComponent as Roadmap } from './../../assets/svgs/QA/roadmap.svg';


export const Qa = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'QA offering at Nexara';
       
      }, []);
    const questions = [
        { q: 'Notice slowdowns in your web apps after updates?', icon: 'slow' },
        { q: 'See rising cloud costs?', icon: 'cost' },
        { q: 'Want efficient cloud use without losing performance?', icon: 'slow' },
        { q: 'Need your cloud setup to auto-adjust for user traffic?', icon: 'slow' }
    ];

    const questions2 = [
        { q: 'Find testing cycles painfully slow?', icon: 'slow' },
        { q: 'Depend too much on manual testing?', icon: 'slow' },
        { q: 'Seek quicker, more reliable automated tests?', icon: 'slow' },
        { q: 'Suffer from unreliable automation?', icon: 'slow' },
    ]
    const questions3 = [
        { q: 'Worry about quality as you speed up releases?', icon: 'slow' },
        { q: 'Notice more bugs with faster development cycles?', icon: 'slow' },
        { q: 'Stress over whether all tests are complete before launch?', icon: 'slow' },
        { q: 'Find bugs that should have been caught?', icon: 'slow' },
    ];
    const questions4 = [
        { q: 'Need help getting your apps certified?', icon: 'slow' },
        { q: 'Find it hard to certify your app across different devices and OS versions?', icon: 'slow' },
        { q: 'Notice your app drains the battery too quickly?', icon: 'slow' },
        { q: 'Hear complaints about your app\'s performance?', icon: 'slow' },
        { q: 'Struggle with testing your app in various languages and regions?', icon: 'slow' },
    ]

    const questions5 = [
        { q: 'Think your UX design could be better?', icon: 'slow' },
        { q: 'Have concerns about your product\'s accessibility for all users?', icon: 'slow' },
    ]

    const questions6 = [
        { q: 'Wonder if there are hidden issues that scripted testing hasn\'t uncovered?', icon: 'slow' },
        { q: 'Wish to see how real users might experience your product?', icon: 'slow' },
        { q: 'Aim to enhance your product\'s usability and overall user satisfaction?', icon: 'slow' },
        { q: 'Need a new perspective on your application\'s functionality and design?', icon: 'slow' },
    ]
    const questions7 = [
        { q: 'Concern yourself with safeguarding your user data and systems?', icon: 'slow' },
        { q: 'Strive to meet industry security standards and regulations?', icon: 'slow' },
        { q: 'Want proactive identification of security vulnerabilities?', icon: 'slow' },
        { q: 'Aim to establish trust through solid security practices?', icon: 'slow' },
    ]
    const questions8 = [
        { q: 'Find that despite testing, product quality isn\'t meeting expectations?', icon: 'slow' },
        { q: 'Struggle to leverage the latest tech advancements into your QA practices?', icon: 'slow' },
        { q: 'Feel that your test coverage might be missing critical areas?', icon: 'slow' },
        { q: 'Rely heavily on manual testing, slowing down your release cycles?', icon: 'slow' },
        { q: 'Encounter bugs post-release that should have been caught earlier?', icon: 'slow' },
        { q: 'Feel burdened by too many QA cycles in each release?', icon: 'slow' },
        { q: 'Feel that despite adding more people to the team, you are not getting the outcome you wanted?', icon: 'slow' },
    ]
    const questions9 = [
        { q: 'Need your team to level up in testing and automation?', icon: 'slow' },
        { q: 'Want to ensure your team is up-to-date with current QA practices?', icon: 'slow' },
    ]

    

    const headers = [
        { hd: 'Performance Testing and Cloud Cost Optimisation Services', index: 1, sectionId: 'hd1' },
        { hd: 'Test Automation Services', index: 2, sectionId: 'hd2' },
        { hd: 'Managed QA Services', index: 3, sectionId: 'hd3' },
        { hd: 'Mobile Application Testing Services', index: 4, sectionId: 'hd4' },
        { hd: 'Accessibility and User Experience (UX) Testing Services', index: 5, sectionId: 'hd5' },
        { hd: 'Exploratory Testing Services', index: 6, sectionId: 'hd6' },
        { hd: 'Security Testing Services', index: 7, sectionId: 'hd7' },
        { hd: 'QA Process Improvement Services', index: 8, sectionId: 'hd8' },
        { hd: 'Skill-based QA Trainings', index: 9, sectionId: 'hd9' }
    ];
    const title = "QA Approach";
    const approach = [
        { step: '1', icon: 'discovery', phase: 'Understanding Your Needs', text: 'We start every engagement by learning about your product, challenges, and what success looks like for you. This helps us recommend the right mix of our QA services.' },
        { step: '2', icon: 'plan', phase: 'Tailored QA Plan', text: 'Based on the initial consultation, we craft a QA plan that fits perfectly with your project\'s scale and complexity, selecting from our suite of services like performance testing, security assessments, or full-cycle QA support.' },
        { step: '3', icon: 'test', phase: 'Setting Up the Test Environment', text: 'For selected services, we prepare the necessary test environments, data, and tools. This ensures we’re testing under conditions that closely mimic real-world usage.' },
        { step: '4', icon: 'execution', phase: 'Test Execution', text: 'Depending on your selected services, we conduct : ', 
        more:'yes', 
        test:[
            {title:'Automated Testing', desc:'For repetitive and regression tests to speed up the cycle.'},
            {title:'Manual Testing', desc:'For exploratory, usability, and specific case testing where human insight is invaluable.'},
            {title:'Performance Testing', desc:'To benchmark and optimize your app’s speed and handling under load.'},
            {title:'Security Testing', desc:'To identify vulnerabilities and ensure your product’s defenses are robust.'}] },
        { step: '5', icon: 'report', phase: 'Analysis and Reporting', text: 'For each testing phase, we provide detailed reports outlining findings, from performance bottlenecks to security risks, along with actionable recommendations.' },
        { step: '6', icon: 'feedback', phase: 'Feedback Loop and Adjustments', text: 'We work with you to prioritize issues based on their impact, guiding through the fixes and adjustments needed to enhance your product.' },
        { step: '7', icon: 'validation', phase: 'Final Validation', text: 'Before considering the testing phase complete, we perform a final round of tests to validate that all adjustments have been effective and your product is ready for launch or has improved post-launch.' },
        { step: '8', icon: 'launch', phase: 'Post-Launch Support', text: 'Depending on the agreement, we offer post-launch monitoring and testing to catch and resolve any issues that emerge with real users.' },
        { step: '9', icon: 'improvement', phase: 'Continuous Improvement', text: 'For ongoing projects, we recommend periodic reviews of the testing strategy to adapt to new features, updates, and changing user needs.' },
    ]
    return (

        <>
            <div className=" subpage-header service-overlay dark-bg">
                <div className="container">
                    <h1>Our QA Offerings</h1>
                    {/* <p className="mb-5 subpage-sub-header">We are a creative company that focuses on establishing long-term relationships with customers.</p> */}
                </div>
            </div>

           

            <div className="qa-services-list ">
            
                <div className="">
                    <div className="container p-t-60">
                        <div className="row">
                            <div className="offset-md-1 col-md-10">
                                <div className="row">

                                    {
                                        headers.map((header) => {
                                            return <div className="col-md-4 mb-5" key={header.index}>

                                                <div className='qa-headers'>
                                                    <a href={'#' + header.sectionId}>
                                                        <div className="row">
                                                            <div className="col-md-3 col-2 mb-3">
                                                                <div className="header-ind">
                                                                    <span className="bubble">
                                                                    {/* <span className="ind">{header.index} </span> */}
                                                                    {header.index && header.index === 1 && <Automation />}
                                                                    {header.index && header.index === 2 && <Performance />}
                                                                    {header.index && header.index === 3 && <ManagedQA />}
                                                                    {header.index && header.index === 4 && <MobileTest />}
                                                                    {header.index && header.index === 5 && <Acc />}
                                                                    {header.index && header.index === 6 && <Explore />}
                                                                    {header.index && header.index === 7 && <Security />}
                                                                    {header.index && header.index === 8 && <QaImp />}
                                                                    {header.index && header.index === 9 && <Skill />}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9 col-10"><span className="header-link">{header.hd} </span> </div>
                                                        </div>
                                                    </a>
                                                </div>

                                            </div>

                                        }
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="light-bg">
                    <div className="container p-tb-40">
                        <div id="hd1" className="">
                            <div className="">
                                <h2 className="mb-5 text-center p-tb-20">{headers[0].hd}</h2>
                                <p className="mb-4"><b>Do you :</b></p>
                                <div className="questions-container">
                                    <div className="row">

                                        {
                                            questions.map((q, ind) => {
                                                return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                    <div className='qa-headers'>

                                                        <div className="row">
                                                            <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                                <Slow width="40px" height="40px" />
                                                            </div>
                                                            <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            }
                                            )
                                        }

                                    </div>

                                  
                                </div>

                                <div className="row mt-5 solution-text mb-5">
                                    <div className="col-lg-1 col-md-2 col-2">
                                        <Target  />
                                    </div>
                                    <div className="col-lg-11 col-md-10 col-10">Nexara provides performance testing for web applications and services using tools like JMeter, LoadRunner, Locust, etc. We determine the best instance types and configurations for your services and set up auto-scaling based on traffic trends to optimize cloud use.</div>

                                </div>

                                <div>
                                <div className="mt-3"><p><b>You get :</b></p></div>
                                    <div className="row solution-points-container">
                                        
                                        <div className=" col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Performance Analysis Report</h3>
                                                <p className="points-text">Detailed insights into current application speeds and performance issues.</p>
                                                <Report />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Bottleneck Identification Document</h3>
                                                <p className="points-text">Pinpoints system bottlenecks and their impact on performance.</p>
                                                <Bottleneck />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Optimization Plan</h3>
                                                <p className="points-text">Clear steps to boost performance and cut costs.</p>
                                                <Optimization />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dark-bg">
                    <div className="container p-tb-40">
                        <div id="hd2">
                            <div className="">
                                <h2 className="mb-5 text-center p-tb-20">{headers[1].hd}</h2>
                               

                                <p className="mb-4"><b>Do you :</b></p>
                                <div className="questions-container">
                                    <div className="row">

                                        {
                                            questions2.map((q, ind) => {
                                                return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                    <div className='qa-headers'>

                                                        <div className="row">
                                                            <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                                {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                                {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                            </div>
                                                            <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            }
                                            )
                                        }

                                    </div>


                                </div>

                                <div className="row mt-5 solution-text mb-5 ">
                                    <div className="col-lg-1 col-md-2 col-2">
                                        <Target  />
                                    </div>
                                    <div className="col-lg-11 col-md-10 col-10">Nexara improves your testing process with automation solutions, leveraging tools tailored to your specific needs. Whether it’s for web, mobile apps, or backend services, we utilize the right set of tools (not limited to Selenium, Appium, RestAssured, etc). With expertise across industry domains like E-commerce, Fintech, Healthcare, Travel, etc we not only facilitate your move from manual to automated testing but also revamp poor automation setups for faster, more reliable outcomes at every project phase.
                                    </div>

                                </div>

                                <div>
                                    <div className="row solution-points-container">
                                        <div className=" col-md-12 mt-3"><p><b>You get :</b></p></div>
                                        <div className=" col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Custom Automation Framework</h3>
                                                <p className="points-text">A scalable, tailored framework designed to meet your specific testing requirements.</p>
                                                <Automation />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Automation Setup Guide</h3>
                                                <p className="points-text">Easy-to-follow steps to integrate automation into your systems, complete with CI/CD setup advice.</p>
                                                <Bottleneck />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Test Coverage Report</h3>
                                                <p className="points-text">A detailed review of the areas covered by automated testing, ensuring comprehensive test coverage.</p>
                                                <Coverage />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Training Materials for Your Team</h3>
                                                <p className="points-text">Documentation and training materials to help your team effectively use and manage the automation framework.</p>
                                                <Skill />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="solution-points">
                                                <h3>Post-Implementation Support Plan</h3>
                                                <p className="points-text">A plan outlining the support Nexara will provide after implementation, including any necessary follow-ups or adjustments.</p>
                                                <Support />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="light-bg">
                    <div className="container  p-tb-40">
                        <div id="hd3">
                            <div className="">
                                <h2 className="mb-5 text-center p-tb-20">{headers[2].hd}</h2>
                                <p className="mb-4"><b>Do you :</b></p>
                                <div className="questions-container">
                                    <div className="row">

                                        {
                                            questions3.map((q, ind) => {
                                                return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                    <div className='qa-headers'>

                                                        <div className="row">
                                                            <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                                {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                                {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                            </div>
                                                            <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            }
                                            )
                                        }

                                    </div>


                                </div>

                                <div className="row mt-5 solution-text mb-5">
                                    <div className="col-lg-1 col-md-2 col-2">
                                        <Target  />
                                    </div>
                                    <div className="col-lg-11 col-md-10 col-10">Nexara acts as an extension of your development team, handling the entire software testing in step with your development speed.
                                    </div>

                                </div>


                                <div className="row solution-points-container">
                                    <div className=" col-md-12 mt-3"><p><b>You get :</b></p></div>
                                    <div className=" col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Dedicated QA Team Access</h3>
                                            <p className="points-text">Direct access to a dedicated team of QA professionals for ongoing support, questions, and adjustments throughout the project duration.</p>
                                            <Team />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Testing Coverage Report</h3>
                                            <p className="points-text">Documentation of all testing phases, including areas tested, types of testing conducted (Functional, Integration, End-to-End, System, Usability testing, etc ), and coverage metrics to ensure thoroughness.</p>
                                            <Coverage />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Bug and Issue Reports</h3>
                                            <p className="points-text">Regular reports identifying any bugs or issues found during testing, along with severity levels and recommendations for fixes.</p>
                                            <Report />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Regular Progress Updates</h3>
                                            <p className="points-text">Scheduled updates and review meetings to keep you informed of testing progress, findings, and next steps.</p>
                                            <ProcessUpdate />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="dark-bg">
                    <div className="container p-tb-40">
                        <div id="hd4">
                            <div className="">
                                <h2 className="mb-5 text-center p-tb-20">{headers[3].hd}</h2>
                                <p className="mb-4"><b>Do you :</b></p>
                                <div className="questions-container">
                                    <div className="row">

                                        {
                                            questions4.map((q, ind) => {
                                                return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                    <div className='qa-headers'>

                                                        <div className="row">
                                                            <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                                {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                                {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                            </div>
                                                            <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            }
                                            )
                                        }

                                    </div>


                                </div>

                                <div className="row mt-5 solution-text mb-5">
                                    <div className="col-lg-1 col-md-2 col-2">
                                        <Target />
                                    </div>
                                    <div className="col-lg-11 col-md-10 col-10">With Nexara, your app gets tested on all devices and for every user scenario. We use cloud-based farms for comprehensive testing and partner with local QA experts for precise localization. Get your app ready for global users with no hassle.
                                    </div>

                                </div>


                                <div className="row solution-points-container">
                                    <div className=" col-md-12 mt-3"><p><b>You get :</b></p></div>
                                    <div className=" col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Regular Testing and Updates Report</h3>
                                            <p className="points-text">Continuous testing focuses on functionality, with regular reports that track progress and pinpoint areas for enhancement.</p>
                                            <Report />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Comprehensive Test Report</h3>
                                            <p className="points-text">Summarizes findings from functional, performance, and localization testing, offering actionable insights for improvement.</p>
                                            <TestReport />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Optional Mobile Automation</h3>
                                            <p className="points-text">Extend your testing with our mobile automation services for even faster and more efficient QA cycles.</p>
                                            <MobileTest />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="light-bg">
                    <div className="container p-tb-40">
                        <div id="hd5">
                            <div className="">
                                <h2 className="mb-5 text-center p-tb-20">{headers[4].hd}</h2>
                                <p className="mb-4"><b>Do you :</b></p>
                                <div className="questions-container">
                                    <div className="row">

                                        {
                                            questions5.map((q, ind) => {
                                                return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                    <div className='qa-headers'>

                                                        <div className="row">
                                                            <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                                {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                                {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                            </div>
                                                            <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            }
                                            )
                                        }

                                    </div>


                                </div>

                                <div className="row mt-5 solution-text mb-5">
                                    <div className="col-lg-1 col-md-2 col-2">
                                        <Target  />
                                    </div>
                                    <div className="col-lg-11 col-md-10 col-10">At Nexara, we review your app or website to identify usability issues, then guide you on how to enhance the user experience. Our aim is to make your product not just easy to use but also a joy for everyone.
                                    </div>

                                </div>


                                <div className="row solution-points-container">
                                    <div className=" col-md-12 mt-3"><p><b>You get :</b></p></div>
                                    <div className=" col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Usability Report</h3>
                                            <p className="points-text">Clear findings on usability issues and how to fix them.</p>
                                            <Usability />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Accessibility Compliance Audit</h3>
                                            <p className="points-text">Details how your product stands against legal accessibility standards, with specific recommendations for compliance.</p>
                                            <Acc />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Bug and Issue Reports</h3>
                                            <p className="points-text">Regular reports identifying any bugs or issues found during testing, along with severity levels and recommendations for fixes.</p>
                                            <Slow />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>UX & Accessibility Enhancements Plan</h3>
                                            <p className="points-text">Straightforward steps to improve UX and accessibility.</p>
                                            <QaImp />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="solution-points">
                                            <h3>Competitor Insights</h3>
                                            <p className="points-text">Competitor Insights</p>
                                            <Performance />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dark-bg">
                    <div className="container p-tb-40">
                        <div id="hd6"> <div className="">
                            <h2 className="mb-5 text-center p-tb-20">{headers[5].hd}</h2>
                            <p className="mb-4"><b>Do you :</b></p>
                            <div className="questions-container">
                                <div className="row">

                                    {
                                        questions6.map((q, ind) => {
                                            return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                <div className='qa-headers'>

                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                            {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                            {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                        </div>
                                                        <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                    </div>

                                                </div>

                                            </div>

                                        }
                                        )
                                    }

                                </div>


                            </div>

                            <div className="row mt-5 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-2">
                                    <Target  />
                                </div>
                                <div className="col-lg-11 col-md-10 col-10">Nexara delves deep with Exploratory Testing Services, leveraging our testers' expertise and intuition to simulate real-world usage and uncover insights that scripted tests might miss. Our goal is to improve your product’s intuitiveness, functionality, and design, ensuring a seamless user experience.
                                </div>

                            </div>


                            <div className="row solution-points-container">
                                <div className=" col-md-12 mt-3"><p><b>You get :</b></p></div>
                                <div className=" col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Exploratory Testing Report</h3>
                                        <p className="points-text">Findings from our hands-on testing, including unexpected behaviors, bugs, and usability challenges.</p>
                                        <Explore />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Usability Insights</h3>
                                        <p className="points-text">Concrete feedback on enhancing user interactions and satisfaction.
                                            Risk Assessment: Identification of potential risks with prioritization for effective mitigation.
                                        </p>
                                        <Usability />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Improvement Strategies</h3>
                                        <p className="points-text"> Tailored recommendations for addressing issues and refining your product.</p>
                                        <QaImp />
                                    </div>
                                </div>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div className="light-bg">
                    <div className="container p-tb-40">
                        <div id="hd7"> <div className="">
                            <h2 className="mb-5 text-center p-tb-20">{headers[6].hd}</h2>
                            <p className="mb-4"><b>Do you :</b></p>
                            <div className="questions-container">
                                <div className="row">

                                    {
                                        questions7.map((q, ind) => {
                                            return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                <div className='qa-headers'>

                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                            {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                            {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                        </div>
                                                        <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                    </div>

                                                </div>

                                            </div>

                                        }
                                        )
                                    }

                                </div>


                            </div>

                            <div className="row mt-5 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-2">
                                    <Target  />
                                </div>
                                <div className="col-lg-11 col-md-10 col-10">With Nexara’s Security Testing Services, we rigorously assess your application's security measures to identify vulnerabilities and ensure compliance with industry standards. Our focus is on fortifying your defenses, safeguarding user data, and maintaining trust through comprehensive security strategies.
                                </div>

                            </div>


                            <div className="row solution-points-container">
                                <div className=" col-md-12 mt-3"><p><b>You get :</b></p></div>
                                <div className=" col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Security Audit Report</h3>
                                        <p className="points-text">An exhaustive evaluation of your security setup, spotlighting weaknesses and compliance shortfalls.</p>
                                        <Audit />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Vulnerability Remediation Plan</h3>
                                        <p className="points-text">Detailed actions to rectify found vulnerabilities, enhancing your security.</p>
                                        <Bottleneck />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Compliance Guide</h3>
                                        <p className="points-text">A roadmap to achieving and maintaining compliance with essential security standards.</p>
                                        <Roadmap />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Security Best Practices</h3>
                                        <p className="points-text">Guidance on integrating continuous security measures into your development processes to deter future threats.</p>
                                        <Security />
                                    </div>
                                </div>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div className="dark-bg">
                    <div className="container p-tb-40">
                        <div id="hd8"> <div className="">
                            <h2 className="mb-5 text-center p-tb-20">{headers[7].hd}</h2>
                            <p className="mb-4"><b>Do you :</b></p>
                            <div className="questions-container">
                                <div className="row">

                                    {
                                        questions8.map((q, ind) => {
                                            return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                <div className='rvices-list'>

                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                            {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                            {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                        </div>
                                                        <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                    </div>

                                                </div>

                                            </div>

                                        }
                                        )
                                    }

                                </div>


                            </div>

                            <div className="row mt-5 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-2">
                                    <Target  />
                                </div>
                                <div className="col-lg-11 col-md-10 col-10">Nexara offers QA consulting services to identify opportunities for improvement and an actionable plan for their execution. We do this by assessing your entire QA landscape, from reviewing your test suites and testing strategies & technologies to aligning them with business objectives.
                                </div>

                            </div>


                            <div className="row solution-points-container">
                                <div className=" col-md-12 mt-3"><p><b>You get :</b></p></div>
                                <div className=" col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>QA Health Check Report</h3>
                                        <p className="points-text"> An in-depth analysis of your current QA processes, identifying strengths and pinpointing areas that have opportunities for improvement.</p>
                                        <Report />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Technology and Tools Assessment</h3>
                                        <p className="points-text">Insights into the latest relevant QA technologies and tools, including a plan to integrate them into your processes.</p>
                                        <Tools />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Test Coverage Optimization Plan</h3>
                                        <p className="points-text">An Insight into optimising your test plans and test cases to ensure the coverage and efficiency.</p>
                                        <Coverage />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>QA Alignment Roadmap</h3>
                                        <p className="points-text">Strategies to align QA efforts more closely with business goals, ensuring every test cycle delivers value to the broader company objectives.</p>
                                        <Roadmap />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="solution-points">
                                        <h3>Actionable Steps for Manual to Automation Transition:</h3>
                                        <p className="points-text">We provide a phased approach to introducing automation, highlighting potential quick wins and long-term benefits.</p>
                                        <Automation />
                                    </div>
                                </div>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div className="light-bg">
                    <div className="container p-tb-40 mb-5">
                        <div id="hd9"> <div className="">
                            <h2 className="mb-5 text-center p-tb-20">{headers[8].hd}</h2>
                            <p className="mb-4"><b>Do you :</b></p>
                            <div className="questions-container">
                                <div className="row">

                                    {
                                        questions9.map((q, ind) => {
                                            return <div className={ind % 2 === 0 ? "col-md-6 mb-3 text-left" : "col-md-6 mb-3"} key={q.q}>

                                                <div className='qa-headers'>

                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-2 col-2 question-icon">
                                                            {q.icon === 'slow' && <Slow width="40px" height="40px" />}
                                                            {q.icon === 'cost' && <Money width="40px" height="40px" />}
                                                        </div>
                                                        <div className="col-lg-11 col-md-10 col-10 question-text">{q.q}  </div>
                                                    </div>

                                                </div>

                                            </div>

                                        }
                                        )
                                    }

                                </div>


                            </div>

                            <div className="row mt-5 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-2">
                                    <Target  />
                                </div>
                                <div className="col-lg-11 col-md-10 col-10">Nexara provides QA training and workshops that cover everything your team needs—from manual testing fundamentals to advanced automation techniques for front-end and back-end, as well as performance testing for web and mobile apps. Our hands-on sessions are tailored to equip your team with the latest tools and practices, ensuring they're prepared to meet any testing requirement.
                                </div>

                            </div>



                        </div></div>
                    </div>
                </div>
            </div>


            <div className="pos-relative">
                {/* <div className="curved-separater reverted p-t-60"> */}
                    <div className="container-fluid">
                        <Approach approach={approach} title={title} />
                    </div>
                {/* </div> */}
            </div>
            <div className="container-fluid mt-5 mb-5" id="faq">
                <h2 className="text-center">Quality Assurance FAQs</h2>
                <div className="row">
                    <div className="col-md-6 offset-md-3">

                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                        Can Nexara handle QA for both small projects and large-scale enterprise applications?
                                    </button>
                                </h3>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Yes, Nexara's QA services scale to any project size. For large projects, we leverage a combination of automated testing tools, a robust team of QA professionals, and proven project management strategies to ensure comprehensive coverage and quality.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        How do you tailor your QA services for different industry needs?
                                    </button>
                                </h3>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">We customize our QA approach by first understanding your specific industry challenges and goals. This includes using industry-relevant testing tools and methodologies to ensure our services meet your requirements.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Do you help fix issues after our project is live?
                                    </button>
                                </h3>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">After your project goes live, Nexara helps make sure everything runs smoothly. We provide free support to fix any issues post release and help you get used to the new system. Later, if you need more help, we can provide it as an extra service.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        How does Nexara integrate QA into our development workflow?
                                    </button>
                                </h3>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">By understanding your project's pace, Nexara schedules QA tasks to match your deadlines. We're part of your planning meetings, fit our testing into your Agile sprints, or jump in at key stages in your Waterfall process, making sure QA complements your development without disrupting it.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        What sets Nexara's performance testing apart?
                                    </button>
                                </h3>
                                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">We fine-tune your web apps for peak performance and offer cloud cost optimization as an added option. By using the necessary testing and reporting tools, Nexara identifies where improvements are needed and suggests adjustments. Whether it's load testing, stress testing, or optimizing cloud usage, we tailor our approach to your specific needs.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                        Can Nexara help reduce the time taken to get our product to market?
                                    </button>
                                </h3>
                                <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Yes, by streamlining testing processes and integrating efficient QA strategies, Nexara can significantly shorten your product's time to market. Our targeted approach ensures thorough testing without unnecessary delays.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingSeven">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                        How does Nexara approach test automation for legacy systems?
                                    </button>
                                </h3>
                                <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">For legacy systems, Nexara carefully evaluates your existing setup to identify the best areas for automation. We then introduce automation in a way that complements your system, focusing on areas that deliver immediate benefits and gradually expanding to cover more ground. This ensures a smooth transition and enhances efficiency without overhauling your entire system.</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </>
    );
};
