import React, {useEffect} from "react";
import './Web.css';
import { ReactComponent as ResponsiveW } from './../../assets/svgs/Web/responsive.svg';
import { ReactComponent as WebDesign } from './../../assets/svgs/Web/web.svg';
import { ReactComponent as MonitorWithSearch } from './../../assets/svgs/Web/monitor-search.svg';
import { ReactComponent as WebContent } from './../../assets/svgs/Web/web-content.svg';
import { ReactComponent as OStore } from './../../assets/svgs/Web/online-store.svg';
import { ReactComponent as Host } from './../../assets/svgs/Web/host.svg';
import { ReactComponent as Mob } from './../../assets/svgs/Web/mobile.svg';
import { ReactComponent as Manage } from './../../assets/svgs/Web/manage.svg';
import { ReactComponent as Pwa } from './../../assets/svgs/Web/pwa.svg';
import { Approach } from "../Approach/Approach";


export const Web = () => {
    const title = "Web Development Approach";
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Web Services at Nexara';
      }, []);
    const approach = [
        { step: '1', icon: 'discovery', phase: 'Discovery Phase', text: 'Understanding your requirements, target audience, and goals.' },
        { step: '2', icon: 'plan', phase: 'Planning and Design', text: 'Creating a roadmap, wireframes, and technical specifications. Developing mockups and prototypes for visual and UX design.' },
        { step: '3', icon: 'dev', phase: 'Development', text: 'Coding the website functionalities using best practices and latest technologies that fit.' },
        { step: '4', icon: 'test', phase: 'Testing', text: 'Conducting a thorough testing for functionality, UX, and performance.' },
        { step: '5', icon: 'optimization', phase: 'Optimization', text: 'Optimizing for speed, SEO, and user experience across all devices.' },
        { step: '6', icon: 'launch', phase: 'Launch', text: 'Deploying website, monitoring performance, and ensuring smooth launch.' },
        { step: '7', icon: 'support', phase: 'Support', text: 'Providing ongoing maintenance, updates, and support post-launch.' }
    ]
    const webService = [
        {q: 'Are you worried about creating a website that stands out in today’s competitive market?', a: 'At Nexara, our custom website design and development services are tailored for businesses, organizations, or individuals seeking to make a strong online presence. We ensure your website is responsive, ensuring compatibility across all devices and platforms.', icon: 'webdesign'},
        {q: 'Are you struggling to keep up with the dynamics of web application development?', a: 'At Nexara, we specialize in building custom, interactive, and dynamic web applications using cutting-edge technologies like React, Angular, and Vue.js. Tailored to meet your specific business needs, our solutions are designed for scalability and performance.', icon: 'responsive'},
        {q: 'Are you finding it challenging to manage your website content efficiently?', a: 'We provide comprehensive CMS solutions, customizing platforms like WordPress, Drupal, or Joomla, enabling you to manage your content effortlessly and keep your site updated without technical help.', icon: 'content'},
        {q: 'Are you looking to launch or upgrade your online store?', a: 'Our e-commerce solutions are designed to set up secure, user-friendly online stores, integrating with platforms like Shopify or Magento and setting up payment gateways for a seamless shopping experience.', icon: 'launch'},
        {q: 'Do you need reliable web hosting and maintenance?', a: 'We at Nexara offer hosting solutions and ongoing support for server setup, website updates, security, and performance, ensuring your online presence is always optimized and secure.', icon: 'host'},
        {q: 'Do you want to extend your reach to mobile users?', a: 'Our experts at Nexara develop responsive web and native mobile applications for iOS and Android, ensuring a seamless user experience across all devices.', icon: 'mobile'},
        {q: 'Are you looking to improve your website’s visibility on search engines?', a: 'Nexara’s SEO services are tailored to enhance your website\'s ranking, implementing best practices right from the development phase to ensure maximum visibility.', icon: 'seo'},
        {q: 'Are you looking for guidance in managing your web projects?', a: 'We provide training, workshops, and consultation on technology choices, best practices, and strategic planning to ensure your team is well-equipped and your projects are future-proof.', icon: 'manage'},
        {q: 'Do you want to offer your users an app-like experience on your website?', a: 'Nexara offers development of Progressive Web Applications (PWA), combining the best of web and mobile app features for an app-like experience, offline functionality, and improved performance, all while being cost-effective and easy to maintain.', icon: 'pwa'},
    ]
    return (

        <>

            <div className=" subpage-header service-overlay light-bg">
                <div className="container-fluid">
                    <h1>WEB APPLICATION DEVELOPMENT SERVICES</h1>
                    {/* <p className="mb-5 subpage-sub-header">Empowering Your Digital Journey with Exceptional Web Services</p> */}
                </div>
            </div>

          


            <div className="dark-bg">
               
                <div className="container-fluid web-services p-t-60">
                   
                    

                    {webService.map((service)=>(
                        <div className="row" key={service.icon}>
                        <div className="col-lg-1 col-md-2 col-sm-2 col-3 offset-md-1 web-svg-container">
                            <div className="web-svg">
                            {service && service.icon === 'webdesign' && <WebDesign />}
                            {service && service.icon === 'responsive' && <ResponsiveW />}
                            {service && service.icon === 'content' && <WebContent />}
                            {service && service.icon === 'launch' && <OStore/>}
                            {service && service.icon === 'host' && <Host />}
                            {service && service.icon === 'mobile' && <Mob />}
                            {service && service.icon === 'seo' && <MonitorWithSearch />}
                            {service && service.icon === 'manage' && <Manage />}
                            {service && service.icon === 'pwa' && <Pwa />}
                            </div>
                        </div>

                        <div className="col-lg-9 col-md-8 col-sm-10 col-9">
                            <h4><b>{service.q}</b></h4>

                            <p className="grey-text">{service.a}</p>

                        </div>
                    </div>
                    )

                    )}
                   


                </div>

                <div className="pos-relative">
                    <div className="p-tb-40 mb-5">
                        <div className="container-fluid">
                            <Approach approach={approach} title={title} />
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5 mb-5" id="faq">
                    <h2 className="text-center">Web Development FAQs</h2>
                    <div className="row">
                        <div className="col-md-6 offset-md-3">

                            <div className="accordion accordion-flush" id="accordionFlushExample">
                              
                                <div className="accordion-item">
                                    <h3 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            How long does it take to build a website?
                                        </button>
                                    </h3>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Typically, it takes a couple of weeks to build a website. However, this can vary based on the size and functionalities you require.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h3 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            What do I need to provide to get my website built?
                                        </button>
                                    </h3>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">We will need basic information like your business details, content (text, images), and any specific preferences you have for the website's design and functionality.</div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h3 className="accordion-header" id="flush-heading4">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="true" aria-controls="flush-collapse4">
                                            Can you help with website maintenance after it is built?
                                        </button>
                                    </h3>
                                    <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Yes, we offer ongoing support and maintenance services to keep your website running smoothly and up to date.</div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h3 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            Do you offer services for updating, modifying, or fixing existing websites?
                                        </button>
                                    </h3>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Yes. This includes tasks such as adding new pages, adding payment gateways, enhancing design elements, optimizing performance, and making any other necessary changes to ensure your website meets your evolving needs and goals.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>





        </>
    );
};
