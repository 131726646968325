import React, { useEffect } from "react";
import { ReactComponent as Boost } from './../../../assets/svgs/Blogs/boost.svg';
import './Performance.css';

export const PerformanceBlog = () => {

    useEffect(() => {
        document.title = 'Improve Performance blog  - Nexara';
        window.scrollTo(0, 0)
    }, []);
    function copyTextToClipboard(event, codeToCopyId) {
        let clickedBtn = event.target;
        const textToCopy = document.getElementById(codeToCopyId).innerText;

        // Create a temporary textarea element
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = textToCopy;
        document.body.appendChild(tempTextarea);

        // Select the text in the textarea
        tempTextarea.select();
        document.execCommand('copy');

        // Clean up by removing the temporary textarea
        document.body.removeChild(tempTextarea);

        // Optionally, provide user feedback (e.g., change button text)
        // document.getElementById('copyButton').innerText = 'Copied!';
        clickedBtn.innerText = 'Copied!'
        setTimeout(() => { clickedBtn.innerText = 'Copy code!' }, 4000)
    }

    return (
        <div className="Performance-blog-container">

            <div className="container">
                <div className="subpage-header">
                    <div className="row">
                        <div className="col-md-9 col-12 col-sm-8">
                            <h1 className="mt-5">Boost Your Website in 2024:<br /> 5 Proven Steps to Improve Performance</h1>
                        </div>
                        <div className="col-md-3 col-12 col-sm-4">
                            <Boost />
                        </div>

                    </div>
                </div>
            </div>
            <div className="dark-bg">
                <div className="container p-tb-40">
                    <div className="row">
                        <div className="col-md-9">
                            <h2>Table of Contents</h2>
                            <ol className="">
                                <li><a href="#imgCompression">Compress Images for Faster Loading</a></li>
                                <li><a href="#minimizefile">Minimize CSS and JavaScript for Efficient Loading</a></li>
                                <li><a href="#browsercache">Enable Browser Caching to Reduce Load Times</a></li>
                                <li><a href="#lazyload">Implement Lazy Loading to Speed Up Interactions</a></li>
                                <li><a href="#nexarahelps">How Nexara Can Help</a></li>

                            </ol>
                            <div id="imgCompression">
                                <h2 className="p-t-60">Compress Images for Faster Loading</h2>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-clipboard2-data"></i></span></div>
                                        <div>
                                            <h4 className="">Benefits</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">Decreases page load times by up to 30%.</p>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-alarm"></i></span></div>
                                        <div>
                                            <h4 className="">Time Required</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">10-20 minutes.</p>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-tools"></i></span></div>
                                        <div>
                                            <h4 className="">Tools Required</h4>

                                        </div>
                                    </div>
                                    <ul className="grey-text ml-42px">
                                        <li>Google's <b>PageSpeed Insights</b> (Free): To identify images that slow down your site.</li>
                                        <li><b>TinyPNG </b>(Free for limited use): To compress image file sizes significantly while maintaining quality.</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-bar-chart-steps"></i></span></div>
                                        <div>
                                            <h4 className="">Step-by-Step Guide</h4>

                                        </div>
                                    </div>
                                    <ol className="grey-text ml-42px">
                                        <li><b>Identify large images:</b> Use Google's PageSpeed Insights to find large images that are slowing down your site.</li>
                                        <li><b>Compress images: </b>Use TinyPNG to compress these images.</li>
                                        <li><b>Replace original images: </b>Upload the compressed images to your website.</li>
                                    </ol>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-question-square"></i></span></div>
                                        <div>
                                            <h4 className="">Why This Works</h4>

                                        </div>
                                    </div>
                                    <ul className="grey-text ml-42px">
                                        <li>Compressing images reduces their size, which means they load faster. This improves overall site speed.</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-book"></i></span></div>
                                        <div>
                                            <h4 className="">Learn More</h4>

                                        </div>
                                    </div>
                                    <p className=" ml-42px"><a href="https://www.cloudflare.com/en-gb/learning/performance/speed-up-a-website/" target="_blank">Comprehensive guide on image optimization</a></p>
                                </div>
                            </div>
                            <div id="minimizefile">
                                <h2 className="p-t-60">Minimize CSS and JavaScript for Efficient Loading</h2>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-clipboard2-data"></i></span></div>
                                        <div>
                                            <h4 className="">Benefits</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">Improves loading times by up to 25%.</p>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-alarm"></i></span></div>
                                        <div>
                                            <h4 className="">Time Required</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">20-30 minutes.</p>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-tools"></i></span></div>
                                        <div>
                                            <h4 className="">Tools Required</h4>

                                        </div>
                                    </div>
                                    <ul className="grey-text ml-42px">
                                        <li><b>CSSNano </b> (Free): Minifies CSS files.</li>
                                        <li><b>UglifyJS </b>(Free): Minifies JavaScript files.</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-bar-chart-steps"></i></span></div>
                                        <div>
                                            <h4 className="">Step-by-Step Guide</h4>

                                        </div>
                                    </div>
                                    <ol className="grey-text ml-42px">
                                        <li><b>Identify Large Files:</b> Use tools like your developer tools in the browser or an online service to identify large CSS and JavaScript files.</li>
                                        <li><b>Minify Files: </b>Apply CSSNano to CSS files and UglifyJS to JavaScript files to reduce their file sizes without altering functionality.</li>
                                        <li><b>Upload Minified Versions: </b>Replace the original CSS and JavaScript files on your server with the minified versions to improve loading times.</li>
                                    </ol>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-question-square"></i></span></div>
                                        <div>
                                            <h4 className="">Why This Works</h4>

                                        </div>
                                    </div>
                                    <ul className="grey-text ml-42px">
                                        <li>Smaller file sizes mean that less data needs to be downloaded by browsers, which speeds up the loading process.</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-book"></i></span></div>
                                        <div>
                                            <h4 className="">Learn More</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px"><a href="https://nitropack.io/blog/post/minify-resources" target="_blank">How to Minify CSS, JavaScript, and HTML Easily</a></p>
                                </div>
                            </div>
                            <div id="browsercache">
                                <h2 className="p-t-60">Enable Browser Caching to Reduce Load Times</h2>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-clipboard2-data"></i></span></div>
                                        <div>
                                            <h4 className="">Benefits</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">Speeds up your site by up to 40% for returning visitors.</p>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-alarm"></i></span></div>
                                        <div>
                                            <h4 className="">Time Required</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">15 minutes.</p>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-tools"></i></span></div>
                                        <div>
                                            <h4 className="">Tools Required</h4>

                                        </div>
                                    </div>
                                    <ul className="grey-text ml-42px">
                                        <li><b>.htaccess file access </b> (Technical access required).</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-bar-chart-steps"></i></span></div>
                                        <div>
                                            <h4 className="">Step-by-Step Guide</h4>

                                        </div>
                                    </div>
                                    <ol className="grey-text ml-42px">
                                        <li><b>Open .htaccess File: </b> Find or create this file in your server's root directory.</li>
                                        <li>
                                            <b>Add Caching Directives: </b>
                                            <ul>
                                                <li>For media files (images, icons): Set a long expiration time.</li>
                                                <li>
                                                    <span>For CSS and JavaScript: Set a shorter expiration time.</span>
                                                    <div className="code-snippet mb-5 mt-5">
                                                        <button className="copybtn" onClick={(e) => copyTextToClipboard(e, 'htmlcode')}>Copy Code</button>
                                                        <div id="htmlcode">
                                                            <pre>
                                                                <code >
                                                                    <div className="code-snnippet-container">

                                                                        <div># Enable caching</div>
                                                                        <div>ExpiresActive On</div>
                                                                        <div>ExpiresDefault A0</div>
                                                                        <div>&nbsp;</div>
                                                                        <div># Media: 1 year</div>
                                                                        <div>&lt;FilesMatch "\.(gif|jpg|jpeg|png|webp|svg)$"&gt;</div>
                                                                        <div>ExpiresDefault A31536000</div>
                                                                        <div>&lt;/FilesMatch&gt;</div>
                                                                        <div>&nbsp;</div>
                                                                        <div># CSS, JS: 2 days</div>
                                                                        <div>&lt;FilesMatch "\.(css|js)$"&gt;</div>
                                                                        <div>ExpiresDefault A172800</div>
                                                                        <div>&lt;/FilesMatch&gt;</div>
                                                                    </div>
                                                                </code>
                                                            </pre>
                                                        </div>
                                                    </div>

                                                </li>
                                                <li>Save Changes: Update the file on your server.</li>
                                            </ul>
                                        </li>

                                    </ol>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-question-square"></i></span></div>
                                        <div>
                                            <h4 className="">Why This Works</h4>

                                        </div>
                                    </div>
                                    <ul className="grey-text ml-42px">
                                        <li>Caching stores files locally in users' browsers, reducing load times on subsequent visits.</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-book"></i></span></div>
                                        <div>
                                            <h4 className="">Learn More</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px"><a href="https://www.quattr.com/core-web-vitals/caching-for-page-speed" target="_blank">Browser Caching & Page Speed Optimization</a></p>
                                </div>
                            </div>
                            <div id="lazyload">
                                <h2 className="p-t-60">Implement Lazy Loading to Speed Up Interactions</h2>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-clipboard2-data"></i></span></div>
                                        <div>
                                            <h4 className="">Benefits</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">Enhances initial page load speed by up to 35%.</p>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-alarm"></i></span></div>
                                        <div>
                                            <h4 className="">Time Required</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px">30-45 minutes.</p>
                                </div>

                                {/* <div>
                                <div className="d-flex flex-row align-center mb-1">
                                    <div><span className="small-icon"><i className="bi bi-tools"></i></span></div>
                                    <div>
                                        <h4 className="">Tools Required</h4>

                                    </div>
                                </div>
                                <ul className="grey-text ml-42px">
                                    <li><b>CSSNano </b> (Free): Minifies CSS files.</li>
                                    <li><b>UglifyJS </b>(Free): Minifies JavaScript files.</li>
                                </ul>
                            </div> */}

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-bar-chart-steps"></i></span></div>
                                        <div>
                                            <h4 className="">Step-by-Step Guide</h4>

                                        </div>
                                    </div>
                                    <ol className="grey-text ml-42px">
                                        <li>
                                            <span><b>Modify HTML Tags:</b> Update your &lt;img&gt; and &lt;iframe&gt; tags to include the loading="lazy" attribute.</span>

                                            <div className="code-snippet mb-5 mt-5">
                                                <button className="copybtn" onClick={(e) => copyTextToClipboard(e, 'htmlcodeLazy')}>Copy Code</button>
                                                <div id="htmlcodeLazy">
                                                    <pre>
                                                        <code >
                                                            <div className="code-snnippet-container">

                                                                <div>&lt;img src="image.webp" alt="description" loading="lazy" width="300" height="300" /&gt;</div>
                                                                <div>&lt;iframe src="video.html" loading="lazy"&gt;&lt;/iframe&gt;</div>
                                                            </div>
                                                        </code>
                                                    </pre>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-question-square"></i></span></div>
                                        <div>
                                            <h4 className="">Why This Works</h4>

                                        </div>
                                    </div>
                                    <ul className="grey-text ml-42px">
                                        <li>Lazy loading delays the loading of images and videos until they are about to be viewed, which reduces the load on initial page access.</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className="d-flex flex-row align-center mb-1">
                                        <div><span className="small-icon"><i className="bi bi-book"></i></span></div>
                                        <div>
                                            <h4 className="">Learn More</h4>

                                        </div>
                                    </div>
                                    <p className="grey-text ml-42px"><a href="https://www.cloudflare.com/en-gb/learning/performance/what-is-lazy-loading/" target="_blank">What is lazy loading?</a></p>
                                </div>
                            </div>
                            <div id="nexarahelps">
                                <h2 className="p-t-60">How Nexara Can Help Enhance Your Site's Performance</h2>

                                <p>
                                    Partnering with Nexara brings significant benefits to your website's performance and overall user experience:
                                </p>
                                <h4>Detailed Performance Audits:</h4>
                                <p>We conduct thorough evaluations to identify and address hidden issues that may be affecting your site's speed.</p>

                                <h4>Custom Optimization Plans:</h4>
                                <p>Our team develops tailored strategies designed specifically to meet your website's unique requirements.</p>

                                <h4>Ongoing Support and Updates:</h4>
                                <p>We offer continuous monitoring and support to ensure your site remains fast, secure, and efficient over time.</p>

                                <h4>Enhanced User Experience:</h4>
                                <p>Faster load times lead to better user engagement and higher conversion rates.</p>
                                
                                <h4>Improved SEO Rankings:</h4>
                                <p> A well-optimized site ranks higher in search results, attracting more organic traffic.</p>

                                <p>Interested in taking your site’s performance to the next level? Contact Nexara today for a <a href="https://www.nexara.in/#contactus">free consultation</a>.</p>

                            </div>




                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>

        </div>

    );
};


