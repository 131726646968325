import React from "react";
import './Banner.css'
import { ReactComponent as Arrow } from './../../assets/svgs/rightArraow.svg';
import { Link } from 'react-router-dom';
import rocket from './../../assets/images/rocket.WebP';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const Banner = () => {
    const navigate = useNavigate();
    const navigatetoId = (navId) =>{
      navigate('/', { replace: true });
      
      
     setTimeout(()=>{
      const myElement = document.getElementById(navId);
      myElement.scrollIntoView()}, 500) ;
    }

    return (
        <div>
            <div className="pos-relative banner-overlay p-tb-40">
                <div className=" container-fluid p-tb-40">
                    <div className=" banner">
                        <div className="text-center banner-text-container mb-5">
                            <h1 className="left-anim">Build, Test, Grow</h1>
                            <p className="grey-text">Solutions, support, and strategy: Everything you need to move forward.</p>

                            <div className="mt-5">
                            <HashLink smooth to='/#services' className="prime-btn left-anim left-anim2 ">
                                    <Arrow loading="lazy"/>
                                    <span className="link-text">Browse Our Services</span>
                                </HashLink>
                            </div>          </div>
                        <div className="">
                            <div className="rocket-container">
                                 <img src={rocket} alt="grow business" loading="lazy"/> 
                                
                                
                            </div>
                        </div>
                    </div>

                </div>
                

            </div>
        </div>
    );
};
