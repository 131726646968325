import React from "react";
import './Card.css'
import { Link } from 'react-router-dom';
import web from './../../assets/images/web-app.webp'
 

export const Card = ({service}) => {
    return (
        <div className="card-container">
        
                
                {/* <div className="card-icon"><Seo/></div> */}
                <div className="service-card-img"><img src={web} alt="web development"/></div>
                {service && service.icon === 'web' && <h4>Web Services</h4>}
                {service && service.icon === 'qa' && <h4>QA Services</h4>}
                {service && service.icon === 'qa' && <p>Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida eget metus cras justo. Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida eget metus cras justo.</p>}
                {service && service.icon === 'marketing' && <h4>Marketing Services</h4>}
                <p>Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida eget metus cras justo.</p>
                {service && service.icon === 'web' && <Link to="/webofferings" role="button">Learn More</Link>}
                {service && service.icon === 'qa' && <Link to="/qaofferings" role="button">Learn More</Link>}
                {service && service.icon === 'marketing' && <Link to="/marketingofferings" role="button">Learn More</Link>}
            
        </div>
    );
};
