import React, {useState, useRef, useEffect} from "react";
import "./Header.css"
import { Link } from 'react-router-dom';
import { ReactComponent as Hamburger } from './../../assets/svgs/hamburger.svg';
import Logo from './../../assets/images/logo.WebP';
import { HashLink } from 'react-router-hash-link';


export const Header = () => {
  const expandedNav = useRef();
  const expandedNav2 = useRef();
  const expandNav2Btn = useRef();
  const dropDownBtn = useRef();


  const [activeLink, setActiveLink] = useState('home');

  const handleLinkClick = (link) => {
    setActiveLink(link);
    expandedNav?.current?.classList.remove('show');
    expandedNav2?.current?.classList.remove('show');
  };
  const closeNav = (link) => {
    expandedNav?.current?.classList.remove('show');
  };
  const expandNav2 = (link) => {
    expandedNav2?.current?.classList.toggle('show');
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!expandNav2Btn?.current?.contains(event.target) && !dropDownBtn?.current?.contains(event.target)) {
       
        expandedNav2?.current?.classList.remove('show');
        setOpen(false);
        
      }
      
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [expandNav2Btn]);

  return (
    <div className="">
      <nav className="navbar navbar-expand-lg navbar-light container-fluid">
        {/* <a className="navbar-brand" href="#">NEXARA</a> */}
        <Link to="/" className="navbar-brand">
          
          <div className="nexara-brand">
            <img src={Logo} alt="Nexara logo" fetchpriority="high" />
          </div>

        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"> <Hamburger className="hamburger-menu" loading="lazy"/></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={expandedNav}>
          <div className="closeIcon" onClick={closeNav}><i className="bi bi-x-lg"></i></div>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              {/* <a className="nav-link" href="#">Home </a> */}
              <Link to="/" className={activeLink === 'home' ? 'nav-link active' : 'nav-link'} onClick={() => handleLinkClick('home')}>Home</Link>
            </li>

            <li className="nav-item dropdown">
              <Link to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={activeLink === 'web' || activeLink === 'qa' || activeLink === 'marketing' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'}>
                Our Offerings
              </Link>
              <div className="dropdown-menu fadeIn" aria-labelledby="navbarDropdown">
                <Link to="/webofferings" className={activeLink === 'web' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => handleLinkClick('web')}>Web</Link>
                <Link to="/qaofferings" className={activeLink === 'qa' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => handleLinkClick('qa')} >QA</Link>
                <Link to="/marketingofferings" className={activeLink === 'marketing' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => handleLinkClick('marketing')}>Marketing</Link>

              </div>
            </li>
            {/* <li className="nav-item">
            <Link to="/blogs" className="nav-link" >Blogs</Link>
            </li> */}
            <li className="nav-item">
              {/* <a className="nav-link" href="#">Home </a> */}
              <Link to="/blogs" className={activeLink === 'blogs' ? 'nav-link active' : 'nav-link'} onClick={() => handleLinkClick('blogs')}>Blogs</Link>
            </li>
            {/* <li className="nav-item">
             
              <a className={activeLink === 'about' ? 'nav-link active' : 'nav-link'} onClick={(e)=>{ handleLinkClick('about'); navigatetoId('aboutus');}}>About Us</a>
              
            </li> */}
              <li className="nav-item">
              <HashLink smooth to='/#aboutus' className={activeLink === 'about' ? 'nav-link active' : 'nav-link'} onClick={(e)=>{ handleLinkClick('about');}}>About Us</HashLink>
            </li>
            <li className="nav-item">
              {/* <Link className="nav-link" to="/contact" >Let's Talk</Link> */}
              <HashLink smooth to='/#contactus' className={activeLink === 'contact' ? 'nav-link active' : 'nav-link'} onClick={(e)=>{ handleLinkClick('contact');}}>Let's Talk</HashLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="fixedNav" >
        <button className="navbar-toggler" type="button" ref={expandNav2Btn} onClick={expandNav2}>
          <span className="navbar-toggler-icon">
            <Hamburger className="hamburger-menu" loading="lazy"/>
          </span>
        </button>
        <div className="collapse navbar-collapse" ref={expandedNav2}>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              {/* <a className="nav-link" href="#">Home </a> */}
              <Link to="/" className={activeLink === 'home' ? 'nav-link active' : 'nav-link'} onClick={() => handleLinkClick('home')}>Home</Link>
            </li>

            <li className="nav-item dropdown">
            <Link id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false" className={activeLink === 'web' || activeLink === 'qa' || activeLink === 'marketing' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} ref={dropDownBtn}>
                Our Offerings
              </Link>
              <div className="dropdown-menu fadeIn show" >
                <Link to="/webofferings" className={activeLink === 'web' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => handleLinkClick('web')}>Web</Link>
                <Link to="/qaofferings" className={activeLink === 'qa' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => handleLinkClick('qa')} >QA</Link>
                <Link to="/marketingofferings" className={activeLink === 'marketing' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => handleLinkClick('marketing')}>Marketing</Link>

              </div>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#">Home </a> */}
              <Link to="/blogs" className={activeLink === 'blogs' ? 'nav-link active' : 'nav-link'} onClick={() => handleLinkClick('blogs')}>Blogs</Link>
            </li>
            {/* <li className="nav-item">
            
              <a className={activeLink === 'about' ? 'nav-link active' : 'nav-link'} onClick={(e)=>{ handleLinkClick('about'); navigatetoId('aboutus');}}>About Us</a>
              
            </li> */}
             <li className="nav-item">
              <HashLink smooth to='/#aboutus' className={activeLink === 'about' ? 'nav-link active' : 'nav-link'} onClick={(e)=>{ handleLinkClick('about');}}>About Us</HashLink>
            </li>
            <li className="nav-item">
              {/* <Link className="nav-link" to="/contact" >Let's Talk</Link> */}
              <HashLink smooth to='/#contactus' className={activeLink === 'contact' ? 'nav-link active' : 'nav-link'} onClick={(e)=>{ handleLinkClick('contact')}}>Let's Talk</HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
};