import React, { useEffect } from "react";
import './Seo.js'

export const SeoBlog = () => {
    function copyTextToClipboard(event, codeToCopyId) {
        let clickedBtn = event.target;
        const textToCopy = document.getElementById(codeToCopyId).innerText;

        // Create a temporary textarea element
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = textToCopy;
        document.body.appendChild(tempTextarea);

        // Select the text in the textarea
        tempTextarea.select();
        document.execCommand('copy');

        // Clean up by removing the temporary textarea
        document.body.removeChild(tempTextarea);

        // Optionally, provide user feedback (e.g., change button text)
        // document.getElementById('copyButton').innerText = 'Copied!';
        clickedBtn.innerText = 'Copied!'
        setTimeout(() => { clickedBtn.innerText = 'Copy code!' }, 4000)
    }
    return (
        <div className="seo-consideration-blog-container light-bg">
        {/* <div id="clock-container">
        <div id="hour"></div>
        <div id="minute"></div>
        <div id="second"></div>
    </div> */}
        <div className="container">
            <div className="subpage-header">
                <div className="row">
                    <div className="col-md-9">
                        <h1 className="mt-5">Enhancing SEO: Implementing Code-Level Optimization in Website Development</h1>
                    </div>
                    <div className="col-md-3">
                       
                    </div>
                </div>
            </div>
        </div>
        <div className="dark-bg">
        <div className="container p-tb-40">
            <div className="row">
                <div className="col-md-9">



                    <p>In the vast digital landscape, where every click counts, having a website that stands out amidst the noise is crucial. Search Engine Optimization (SEO) plays a pivotal role in ensuring your website garners the visibility it deserves. While content and keywords are vital components of SEO, delving into code-level optimization during website development can significantly amplify your site's search engine performance. Let's explore the fundamental principles and best practices of code-level SEO implementation.</p>
                    
                    <h2>Semantic HTML for Enhanced Structure:</h2>
                    <p>At the core of every website lies HTML, the language that structures web content. Leveraging semantic HTML not only enhances accessibility but also aids search engines in understanding the hierarchy and context of your content. Utilize semantic elements like <code>&lt;header&gt;</code>, <code>&lt;footer&gt;</code>, <code>&lt;nav&gt;</code>, and <code>&lt;article&gt;</code> to provide clear signals to search engine crawlers.</p>
                    <div className="code-snippet mb-5 mt-5">
                        <button id="copyButton" className="copybtn" onClick={(e) => copyTextToClipboard(e, 'htmlcode')}>Copy Code</button>
                        <div id="htmlcode">
                            <pre>
                                <code >
                                    <div className="code-snnippet-container">
                                    &#x3C;!DOCTYPE html&#x3E;
&#x3C;html lang=&#x22;en&#x22;&#x3E;
&#x3C;head&#x3E;
    &#x3C;meta charset=&#x22;UTF-8&#x22;&#x3E;
    &#x3C;meta name=&#x22;viewport&#x22; content=&#x22;width=device-width, initial-scale=1.0&#x22;&#x3E;
    &#x3C;title&#x3E;Document&#x3C;/title&#x3E;
&#x3C;/head&#x3E;
&#x3C;body&#x3E;
    &#x3C;header&#x3E;
        &#x3C;h1&#x3E;Website Title&#x3C;/h1&#x3E;
        &#x3C;nav&#x3E;
            &#x3C;ul&#x3E;
                &#x3C;li&#x3E;&#x3C;a href=&#x22;/&#x22;&#x3E;Home&#x3C;/a&#x3E;&#x3C;/li&#x3E;
                &#x3C;li&#x3E;&#x3C;a href=&#x22;/about&#x22;&#x3E;About&#x3C;/a&#x3E;&#x3C;/li&#x3E;
                &#x3C;li&#x3E;&#x3C;a href=&#x22;/contact&#x22;&#x3E;Contact&#x3C;/a&#x3E;&#x3C;/li&#x3E;
            &#x3C;/ul&#x3E;
        &#x3C;/nav&#x3E;
    &#x3C;/header&#x3E;
    &#x3C;section&#x3E;
        &#x3C;article&#x3E;
            &#x3C;h2&#x3E;Article Title&#x3C;/h2&#x3E;
            &#x3C;p&#x3E;Article content goes here...&#x3C;/p&#x3E;
        &#x3C;/article&#x3E;
    &#x3C;/section&#x3E;
    &#x3C;footer&#x3E;
        &#x3C;p&#x3E;&#x26;copy; 2024 Your Website&#x3C;/p&#x3E;
    &#x3C;/footer&#x3E;
&#x3C;/body&#x3E;
&#x3C;/html&#x3E;
                                    </div>
                                </code>
                            </pre>
                        </div>
                    </div>
                    <h2>Optimized Metadata:</h2>
                    <p>Include descriptive and keyword-rich metadata in your <code>&lt;head&gt;</code> section, such as title tags and meta descriptions. These elements provide concise summaries of your pages' content and help search engines understand the relevance of your website to user queries.</p>
                    <div className="code-snippet mb-5 mt-5">
                        <button id="copyCSSBtn" className="copybtn" onClick={(e) => copyTextToClipboard(e, 'csscode')}>Copy Code</button>
                        <div id="csscode">
                            <pre>

                                <code>

                                    <div className="code-snnippet-container">
                                        <div>.clock &#123;</div>
                                        <div>position: relative;</div>
                                        <div>margin: auto;</div>
                                        <div>height: 200px;</div>
                                        <div>width: 200px;</div>
                                        <div>border: 1px solid #ffffff;</div>
                                        <div>border-radius: 50%;</div>
                                        <div>background-color: #FFF;</div>
                                        <div>background-image: url('./../../../assets/images/clock.png');</div>
                                        <div>background-size: cover;</div>
                                        <div>&#125;</div>

                                        <div>.hour-hand,</div>
                                        <div>.minute-hand,</div>
                                        <div>.second-hand &#123;</div>
                                        <div>position: absolute;</div>
                                        <div>background: black;</div>
                                        <div>border-radius: 10px;</div>
                                        <div>transform-origin: bottom;</div>
                                        <div>&#125;</div>

                                        <div>.hour-hand &#123;</div>
                                        <div>width: 1.8%;</div>
                                        <div>height: 25%;</div>
                                        <div>top: 25%;</div>
                                        <div>left: 48.85%;</div>
                                        <div>opacity: 0.8;</div>
                                        <div>&#125;</div>

                                        <div>.minute-hand &#123;</div>
                                        <div>width: 1.6%;</div>
                                        <div>height: 30%;</div>
                                        <div>top: 19%;</div>
                                        <div>left: 48.9%;</div>
                                        <div>opacity: 0.8;</div>
                                        <div>&#125;</div>

                                        <div>.second-hand &#123;</div>
                                        <div>width: 1%;</div>
                                        <div>height: 40%;</div>
                                        <div>top: 9%;</div>
                                        <div>left: 49.25%;</div>
                                        <div>opacity: 0.8;</div>
                                        <div>background: red;</div>
                                        <div>&#125;</div>

                                        <div>.center-circle &#123;</div>
                                        <div>width: 12px;</div>
                                        <div>height: 12px;</div>
                                        <div>background-color: #333;</div>
                                        <div>border-radius: 50%;</div>
                                        <div>position: absolute;</div>
                                        <div>top: 0;</div>
                                        <div>left: 0;</div>
                                        <div>bottom: 0;</div>
                                        <div>right: 0;</div>
                                        <div>margin: auto;</div>
                                        <div>&#125;</div>
                                    </div>
                                </code>
                            </pre>
                        </div>
                    </div>
                    <h2>Step 3: Add JavaScript Functionality</h2>
                    <p>Now comes the fun part! Create a script.js file and let's add the JavaScript to make our clock tick:</p>
                    <div className="code-snippet mb-5 mt-5">
                        <button id="copyJSBtn" className="copybtn" onClick={(e) => copyTextToClipboard(e, 'jscode')}>Copy Code</button>
                        <div id="jscode">
                            <pre>
                                <code>
                                    <div className="code-snnippet-container">
                                        <div>function rotateClockHands() &#123;</div>
                                        <div>const now = new Date();</div>
                                        <div>const hours = now.getHours();</div>
                                        <div>const minutes = now.getMinutes();</div>
                                        <div>const seconds = now.getSeconds();</div>

                                        <div></div>const hourHand = document.getElementById(&#x27;hour-hand&#x27;);
                                        <div></div>const minuteHand = document.getElementById(&#x27;minute-hand&#x27;);
                                        <div></div>const secondHand = document.getElementById(&#x27;second-hand&#x27;);

                                        <div></div>const hourAngle = (hours % 12) * 30 + minutes / 2; // 30 degrees per hour, 0.5 degrees per minute
                                        <div></div>const minuteAngle = minutes * 6; // 6 degrees per minute
                                        <div></div>const secondAngle = seconds * 6; // 6 degrees per second

                                        <div></div>hourHand.style.transform = &#x60;rotate($&#123;hourAngle&#125;deg)&#x60;;
                                        <div></div>minuteHand.style.transform = &#x60;rotate($&#123;minuteAngle&#125;deg)&#x60;;
                                        <div></div>secondHand.style.transform = &#x60;rotate($&#123;secondAngle&#125;deg)&#x60;;
                                        <div></div>&#125;
                                        <br />
                                        <br />
                                        <div></div>// Call rotateClockHands every second
                                        <div></div>setInterval(rotateClockHands, 1000);
                                        <br />
                                        <br />
                                        <div></div>// Initialize clock on page load
                                        <div></div>rotateClockHands();
                                    </div>
                                </code>

                            </pre>
                        </div>
                    </div>
                    <h2>Step 4: Test Your Clock</h2>
                    <p>Open your HTML file in a web browser, and voilà! You should see a beautiful analog clock ticking away the seconds.</p>
                    <h2>Conclusion</h2>
                    <p>Congratulations! You've successfully built an analog clock using JavaScript. This project not only enhances your JavaScript skills but also gives you a fun and practical way to understand how to manipulate the DOM and work with time in JavaScript.</p>

                    <p>Feel free to experiment further by adding features like different clock designs, time zones, or even animations to make your clock even more unique. Happy coding!</p>
                </div>
                <div className="col-md-3"></div>
            </div>
        </div>
        </div>

    </div>
    )
}