import React, { useEffect } from "react";
import './Marketing.css';
import { ReactComponent as Slow } from './../../assets/svgs/slow.svg';
import { ReactComponent as Target } from './../../assets/svgs/QA/solution.svg';
import { Approach } from "../Approach/Approach";

export const Marketing = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Marketing services at Nexara';
    }, []);


    const title = "Digital Marketing Approach";
    const approach = [
        { step: '1', icon: 'discovery', phase: 'Discovery', text: 'Understanding your goals, target audience, and industry landscape.' },
        { step: '2', icon: 'research', phase: 'Market Research', text: 'Conducting a thorough research on competitors, trends, and audience behavior.' },
        { step: '3', icon: 'analysis', phase: 'Keyword Analysis', text: 'Identifying relevant keywords for SEO and PPC campaigns.' },
        { step: '4', icon: 'content', phase: 'Content Creation', text: 'Developing high-quality and engaging content for various channels.' },
        { step: '5', icon: 'optimization', phase: 'Search Engine Optimization (SEO)', text: 'Optimizing website content, meta tags, and structure for search engines.' },
        { step: '6', icon: 'ppc', phase: 'PPC Campaigns', text: 'Setting up and managing pay-per-click advertising campaigns for targeted reach.' },
        { step: '7', icon: 'media', phase: 'Social Media Marketing', text: 'Creating and sharing relevant content on social media platforms.' },
        { step: '8', icon: 'email', phase: 'Email Marketing', text: 'Developing email campaigns for lead generation and customer retention.' },
        { step: '9', icon: 'analytics', phase: 'Analytics and Tracking', text: 'Implementing tools for monitoring and analyzing campaign performance.' },
        { step: '10', icon: 'optimization', phase: 'Optimization', text: 'Continuously optimizing campaigns based on data and insights for maximum ROI.' }
    ]
    return (
        <>
            <div className="subpage-header service-overlay light-bg">
                <div className="container-fluid">
                    <h1>Our services for your digital growth </h1>
                </div>
            </div>

            <div className=" marketing-services">
                <div className="container-fluid">
                    <div className="row align-center">
                        <div className="col-md-5">
                            <div className="text-stroke-img">SEO Audit</div>
                        </div>
                        <div className="col-md-7">
                            <div className="questions-container">


                                <div className='qa-headers'>

                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-2 question-icon">
                                            <Slow width="40px" height="40px" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-11 col-10 question-text">Is your website not getting enough visitors or ranking well on search results? </div>
                                    </div>

                                </div>



                            </div>

                            <div className="row mt-3 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-sm-1 col-2">
                                    <Target />
                                </div>
                                <div className="col-lg-11 col-md-10 col-sm-11 col-10">We'll conduct a comprehensive SEO audit and develop a tailored strategy to improve your online
                                    visibility, rankings, and organic traffic, ensuring you stay ahead of the competition. </div>

                            </div>


                            {/* </div>
                    </div> */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-center">

                        <div className="col-md-7">
                            <div className="questions-container">
                                <div className='qa-headers'>

                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-2 question-icon">
                                            <Slow width="40px" height="40px" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-11 col-10 question-text">Are you finding it challenging to convert website visitors into leads and sales?</div>
                                    </div>

                                </div>


                            </div>

                            <div className="row mt-3 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-sm-1 col-2">
                                    <Target />
                                </div>
                                <div className="col-lg-11 col-md-10 col-sm-11 col-10">We analyze user behavior, optimize website elements, and use targeted messaging to guide visitors towards conversion actions, resulting in higher-quality leads and improved return on investment. </div>

                            </div>



                        </div>
                        <div className="col-md-5 ">
                            <div className="text-stroke-img text-right">Leads & Sales</div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-center">
                        <div className="col-md-5">
                            <div className="text-stroke-img">PPC & Ad</div>
                        </div>
                        <div className="col-md-7">
                            <div className="questions-container">

                                <div className='qa-headers'>

                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-2 question-icon">
                                            <Slow width="40px" height="40px" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-11 col-10 question-text">Are you looking to reach your target audience quickly and effectively across search engines and social media platforms?</div>
                                    </div>

                                </div>



                            </div>

                            <div className="row mt-3 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-sm-1 col-2">
                                    <Target />
                                </div>
                                <div className="col-lg-11 col-md-10 col-sm-11 col-10">We will leverage pay per click (PPC) and social media advertising to connect with your ideal customers. PPC ads show up on search engines or social media, and you only pay when someone clicks on your ad. This helps target people already interested in what you offer, boosting sales </div>

                            </div>


                            {/* </div>
                    </div> */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-center">

                        <div className="col-md-7">
                            <div className="questions-container">

                                <div className='qa-headers'>

                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-2 question-icon">
                                            <Slow width="40px" height="40px" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-11 col-10 question-text">Do you need strategies to stand out from competitors and differentiate your brand?</div>
                                    </div>

                                </div>


                            </div>

                            <div className="row mt-3 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-sm-1 col-2">
                                    <Target />
                                </div>
                                <div className="col-lg-11 col-md-10 col-sm-11 col-10">We conduct thorough competitive analysis, identify your unique strengths and value propositions, and implement strategic initiatives such as branding campaigns, unique selling points, and differentiated messaging to position your brand  in the market.</div>

                            </div>

                        </div>
                        <div className="col-md-5">
                            <div className="text-stroke-img text-right">stand out</div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-center">
                        <div className="col-md-5">
                            <div className="text-stroke-img">audience</div>
                        </div>
                        <div className="col-md-7">
                            <div className="questions-container">
                                <div className='qa-headers'>

                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-2 question-icon">
                                            <Slow width="40px" height="40px" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-11 col-10 question-text">Are you seeking new ways to market and attract a larger audience?</div>
                                    </div>

                                </div>
                            </div>

                            <div className="row mt-3 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-sm-1 col-2">
                                    <Target />
                                </div>
                                <div className="col-lg-11 col-md-10 col-sm-11 col-10">We analyze market trends and utilize data insights to enhance audience reach and engagement.</div>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-center">

                        <div className="col-md-7">
                            <div className="questions-container">

                                <div className='qa-headers'>

                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-2 question-icon">
                                            <Slow width="40px" height="40px" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-11 col-10 question-text">Are you having trouble being found locally and connecting well with your customers?</div>
                                    </div>

                                </div>
                            </div>

                            <div className="row mt-3 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-sm-1 col-2">
                                    <Target />
                                </div>
                                <div className="col-lg-11 col-md-10 col-sm-11 col-10">We enhance your local search visibility by optimizing keywords and listings for your area</div>

                            </div>

                        </div>
                        <div className="col-md-5">
                            <div className="text-stroke-img text-right">visibility</div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-center">
                        <div className="col-md-5">
                            <div className="text-stroke-img">ROI</div>
                        </div>
                        <div className="col-md-7">
                            <div className="questions-container">

                                <div className='qa-headers'>

                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-2 question-icon">
                                            <Slow width="40px" height="40px" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-11 col-10 question-text">Wondering if your marketing spend is really paying off and maximizing return on investment(ROI)?</div>
                                    </div>

                                </div>
                            </div>

                            <div className="row mt-3 solution-text mb-5">
                                <div className="col-lg-1 col-md-2 col-sm-1 col-2">
                                    <Target />
                                </div>
                                <div className="col-lg-11 col-md-10 col-sm-11 col-10">We adjust your marketing spend to increase profits and use customer and market data to improve ROI.</div>

                            </div>


                        </div>
                    </div>
                </div>


            </div>

            <div className="pos-relative">
                {/* <div className="curved-separater reverted p-t-60"> */}
                <div className="container-fluid p-tb-40">
                    <Approach approach={approach} title={title} />
                </div>
                {/* </div> */}
            </div>
            <div className="container-fluid  mb-5 p-tb-40" id="faq">
                <h2 className="text-center">Digital Marketing FAQs</h2>
                <div className="row">
                    <div className="col-md-6 offset-md-3">

                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                        Can you help with content creation for digital marketing?
                                    </button>
                                </h3>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Yes, we provide content creation services, including writing engaging blog posts, creating eye-catching graphics and videos, and developing compelling ad copy to attract and engage your target audience.</div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h3 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        How do you measure the success of digital marketing campaigns?
                                    </button>
                                </h3>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">We use various metrics like website traffic, conversion rates, click-through rates (CTR), engagement levels, and return on investment (ROI) to measure the success of digital marketing campaigns and make data-driven optimizations for better results.</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}