import React from "react";
import './WhyChooseUs.css';
import { ReactComponent as Need } from './../../assets/svgs/need.svg';
import { ReactComponent as Learn } from './../../assets/svgs/learn.svg';
import { ReactComponent as Together } from './../../assets/svgs/together.svg';
 

export const WhyChooseUs = () => {
  
    return (
        <div className="" id="whyNexara">
        <div className=" p-tb-40">
        <div className="banner-container container-fluid mt-5 whyUs">
        <div className=" banner">
        {/* <div className="col-lg-6 mb-5 reveal-right">
            <div className="img-container">
                <img src={banner} />
            </div>
         </div> */}
        <div className="">
        <h2 className=" text-center">Why Choose Nexara </h2>
            <h5 className=" mb-4 text-center">Finding the right partner for your online projects is key. Here’s why people pick us.</h5>
            <div className="row">
                <div className="col-md-4 mt-5 mb-5">
                    <div className="d-flex flex-row align-center mb-4">
                        <div><span className="small-icon"><Need width="60px" height="60px" loading="lazy"/></span></div>
                        <div>
                            <h4 className="">Just What You Need</h4>
                            {/* <p>Your project is one of a kind. We listen, learn, and then create plans that fit just right.</p> */}
                            </div>
                    </div>
                    <p>Your project is one of a kind. We listen, learn, and then create plans that fit just right.</p>
                </div>
                <div className="col-md-4 mt-5 mb-5">
                    <div className="d-flex flex-row align-center mb-4">
                        <div><span className="small-icon"><Learn width="60px" height="60px" loading="lazy"/></span></div>
                        <div>
                            <h4 className="">Experts Who Keep Learning</h4>
                            
                            </div>
                    </div>
                    <p> Our team knows a lot about building websites, testing them, and getting the word out. We always stay up to date.</p>
                </div>
                <div className="col-md-4 mt-5 mb-5">
                    <div className="d-flex flex-row  align-center mb-4">
                         <div><span className="small-icon"><Together width="60px" height="60px" loading="lazy"/></span></div> 
                        <div>
                            <h4 className="">We’re In This Together</h4>
                            
                            </div>
                    </div>
                    <p>We believe in clear talks, sticking to plans, and making sure you’re happy with the results. Your project gets our full attention.</p>
                </div>
                
            </div>
        
         </div>
         
      </div>
      
      </div>
      </div>
      </div>
    );
};
