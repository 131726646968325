import React from "react";
import './About.css';
import QAtest from './../../assets/images/about/qa-testing-banner.webp'
import Webdev from './../../assets/images/about/web-dev.png'
import DigiMarketing from './../../assets/images/about/digital-mktng2.png';
import AboutUs from './../../assets/images/About-Us.Webp';
import { ReactComponent as Wheel } from './../../assets/svgs/About/wheel.svg';
import { ReactComponent as Comment } from './../../assets/svgs/About/comment.svg';
import { ReactComponent as Heart } from './../../assets/svgs/About/heart.svg';
import { ReactComponent as Marketing } from './../../assets/svgs/About/marketing.svg';


export const About = () => {
   

    return (


        <div className="p-tb-40 p-t-60">
            <div className="container-fluid">
                <h2 className="text-center">About Us</h2>
                <div className="row align-center">
                   
                    <div className="col-md-6 mt-5">
                        <p>We are Nexara. We specialize in web design, web development, software testing, and digital marketing, with a strong emphasis on Local SEO. Our founders bring over 3 decades of cumulative industry expertise, honing their skills in tech startups, big 4 consulting firms, and two of the world's largest marketing agencies. Partner with us and discover how we can elevate your digital presence and drive results that matter to your business. </p>

                    </div>
                    <div className="col-md-6 mt-5">
                        <div className="img-container">
                            <img src={AboutUs} alt="about us" />
                        </div>
                    </div>
                </div>

                
            </div>

        </div>
    );
};
