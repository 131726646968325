import React from "react";
import { Card } from "../Card/Card";


export const Whatwedo = () => {

    return (
        <>
            <div className="dark-bg" id="whatwedo">
                <div className="container p-tb-20">
                    <div className="row text-center">
                        <div className="col-md-10 offset-md-1 col-xxl-8 offset-xxl-2">
                            <h2 className=" theme-text ">WHAT WE DO</h2>
                            <h3 className="">The full service we are offering is specifically designed to meet your business needs.</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 offset-md-1 align-self-end">
                            <Card service={{ icon: 'web' }} />
                        </div>
                        <div className="col-lg-3 align-self-start">
                            <Card service={{ icon: 'qa' }} />
                        </div>
                        <div className="col-lg-3 align-self-end">
                            <Card service={{ icon: 'marketing' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
