import React from "react";
import './Footer.css'
import Logo from './../../assets/images/logo.WebP';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
 

export const Footer = () => {

    return (
    
<>
<footer className="text-center">
 
  <div className="container-fluid">
    
    <section className="">
     
      <div className="row">
     
        <div className="col-md-2 col-sm-4 col-12 mb-4">
        <div className="footer-logo">
          <div className="img-container">
         <Link to="/"> <img src={Logo} alt="footer nexara logo " loading="lazy"/></Link>

          </div>
          </div>
        </div>
       
        <div className="col-md-8 col-sm-4 col-12 mb-4">
          <h6 className="text-uppercase mt-5">Quick Links</h6>

          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/" >Home</Link>
            </li>
            <li className="nav-item">
               <HashLink smooth to='/#aboutus' className="nav-link">About Us</HashLink>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/blogs" >Blogs</Link>
            </li>
            <li className="nav-item">
            <HashLink smooth to='/#contactus' className="nav-link">Let's Talk</HashLink>
            </li>

          
          </ul>
        </div>
        
        <div className="col-md-2 col-sm-4 col-12 mb-4">
          <div>
          <h6 className="text-uppercase mt-5">Services</h6>

          <ul className="navbar-nav mr-auto">
          

            <li className="nav-item">
            <Link to="/webofferings" className="nav-link" >Web</Link>
            </li>
            <li className="nav-item">
            <Link to="/qaofferings" className="nav-link">QA</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/marketingofferings">Marketing</Link>
            </li>
          </ul>
          </div>
        </div>
       
        
      
      </div>
        
    </section>
   
  </div>
  


  <div className="text-center p-3">

<section className="mb-4">
    <Link to="https://twitter.com/NexaraSolutions" className="social-icon" target="_blank" aria-label="Twitter"><i className="bi bi-twitter"></i></Link>
    <Link to="https://www.instagram.com/nexarasolutions/" className="social-icon" target="_blank" aria-label="Instagram"><i className="bi bi-instagram"></i></Link>
    {/* <Link to="/" className="social-icon"><i className="bi bi-whatsapp"></i></Link> */}
    <Link to="https://www.linkedin.com/company/nexara-in" className="social-icon" target="_blank" aria-label="LinkedIn"><i className="bi bi-linkedin"></i></Link>
    <Link to="https://wa.me/+919900121537" className="social-icon" target="_blank" aria-label="WhatsApp"><i className="bi bi-whatsapp"></i></Link>
  
    {/* <Link to="/" className="social-icon"><i className="bi bi-youtube"></i></Link> */}
     
    </section>
  </div>
 
  <hr></hr>
      <div>For inquiries, email us <a href="mailto:info@nexara.in">info@nexara.in</a>.</div>
</footer>
</>

    
    );
};
